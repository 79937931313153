import React, { useCallback, useMemo, useState } from 'react';
import './Staking.css'

import { tokenAddresses } from '../../contracts/address';

import { StakingItem } from './StakingItem';

export const Staking = () => {
  return (
    <div className="account-page page">
      <StakingItem stakingAddress={tokenAddresses.evmospadStaking1} title="Staking #1" forceAPY='30'/>
      <StakingItem stakingAddress={tokenAddresses.evmospadStaking2} title="Staking #2" forceAPY='80' />
    </div>
  )
}
