import React, { useCallback, useMemo, useState } from 'react';
import './FarmingItem.css';
import {
  Col,
  Container,
  Row,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Image
} from 'react-bootstrap';
import {
  BalanceItem,
  CommonTooltip,
  LoadingWrap,
  RoundButton,
  ApprovalSteps
} from '../../components';
import { useApproval } from '../../contracts/hooks/useApproval';
import { tokenAddresses } from '../../contracts/address';
import { useFarming } from '../../contracts/hooks/useFarming';
import {
  balanceToCurrency,
  balanceToNumber,
  balanceToNumeric,
  numberToCurrency,
  numericToBalance,
  numericToUint256
} from '../../utils/balanceFormatter';
import LPLogo from '../../assets/logo.png';
import TokenLogo from '../../assets/logo.png';
import { ReactComponent as LinkArrow } from '../../assets/link-arrow.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import { farmingTooltips } from './tooltips';
import { useNetwork } from '../../hooks/useNetwork';

const buyTokenLink = '#';

enum FormTypes {
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE',
  STATS = 'STATS'
}

type FormType = keyof typeof FormTypes;

interface Props {
  stakingAddress: string;
  title: string;
  forceAPY: string;
}

export const FarmingItem = ({ stakingAddress, title, forceAPY }: Props) => {
  const { isDefaultNetworkSelected } = useNetwork();

  const { allowance, onApprove } = useApproval(
    ...(isDefaultNetworkSelected
      ? [tokenAddresses.lpToken, stakingAddress]
      : [])
  );
  const {
    rewardsAPY,
    lastStaked,
    lastStakedInSeconds,
    rewards,
    staked,
    lpBalance,
    totalStaked,
    lockPeriod,
    onStake,
    onUnstake,
    onClaim,
    decimals
  } = useFarming(stakingAddress);

  const [stakeFormType, setStakeFormType] = useState<FormType>(FormTypes.STAKE);
  const [amountToStake, setAmountToStake] = useState('0');
  const [amountToUnstake, setAmountToUnstake] = useState('0');

  const estimatedRewards = useMemo(() => {
    // return +amountToStake * rewardsAPY.toNumber() / 1e4 / 12;
    return (((+amountToStake * 1e5) / 1e4) * 423.8943651248) / 12;
  }, [amountToStake, rewardsAPY]);

  const setMaxToStake = useCallback(() => {
    setAmountToStake(balanceToNumeric(lpBalance, decimals));
  }, [lpBalance, decimals]);

  const setMaxToUnstake = useCallback(() => {
    setAmountToUnstake(balanceToNumeric(staked, decimals));
  }, [staked, decimals]);

  const disableFarming = useMemo(() => {
    return (
      +amountToStake <= 0 ||
      lpBalance.isLessThan(numericToBalance(amountToStake, decimals)) ||
      allowance.isLessThan(numericToBalance(amountToStake, decimals))
    );
  }, [amountToStake, lpBalance, allowance, decimals]);

  const disableUnstaking = useMemo(() => {
    return (
      +amountToUnstake <= 0 ||
      staked.isLessThan(numericToBalance(amountToUnstake, decimals))
    );
  }, [amountToUnstake, staked, decimals]);

  const handleApprove = () => onApprove();

  const handleStake = useCallback(async () => {
    if (disableFarming) {
      return;
    }
    const amount = numericToUint256(amountToStake, decimals);
    await onStake(amount, {
      onHash: () => setAmountToStake('0')
    });
  }, [amountToStake, onStake, decimals, disableFarming]);

  const handleUnstake = useCallback(async () => {
    if (disableUnstaking) {
      return;
    }
    const amount = numericToUint256(amountToUnstake, decimals);
    await onUnstake(amount, {
      onHash: () => setAmountToUnstake('0')
    });
  }, [amountToUnstake, onUnstake, decimals, disableUnstaking]);

  const handleClaim = useCallback(async () => {
    if (!balanceToNumber(rewards, decimals)) {
      return;
    }
    await onClaim();
  }, [onClaim, decimals, rewards]);

  return (
    <div className="account-staking">
      <section className="stake-allocation-section">
        <Container>
          <Row className="stake-allocation-row">
            <Col className="text-left">
              <h2 className="title">{title}</h2>
            </Col>
          </Row>
          <Row className="stake-block account-staking__stake-block tile">
            <Col xs={{ span: 7 }} xl={{ span: 6 }}>
              <div className="stake-form">
                <Tabs
                  id="stake-block-tabs"
                  className="stake-block-tabs"
                  activeKey={stakeFormType}
                  onSelect={(eventKey) =>
                    setStakeFormType(eventKey as FormType)
                  }
                >
                  <Tab
                    eventKey={FormTypes.STAKE}
                    title={FormTypes.STAKE.toLowerCase()}
                    className="stake-block-tab-stake"
                  >
                    <div className="stake-form__heading">
                      <Image src={LPLogo} width="50px" />
                      <div>
                        <p>
                          <span className="fw-bold">EvmosPad-EVMOS LP </span>
                          <RoundButton
                            href={buyTokenLink}
                            disabled={!buyTokenLink}
                            size="small"
                            color="DARK"
                          >
                            Get the token <LinkArrow />
                          </RoundButton>
                          <CommonTooltip id="lp-token-tooltip">
                            {farmingTooltips.getTokenLink}
                          </CommonTooltip>
                        </p>
                        <p className="info-list">
                          <span className="name">Available to stake: </span>
                          <span className="value">
                            {balanceToCurrency(lpBalance, decimals)}
                          </span>
                        </p>
                      </div>
                    </div>
                    <InputGroup className="stake-form__input-group input-group-big">
                      <InputGroup.Prepend>Amount:</InputGroup.Prepend>
                      <FormControl
                        placeholder="0.0"
                        type="number"
                        inputMode="numeric"
                        value={amountToStake}
                        onChange={(e) => setAmountToStake(e.target.value)}
                        isInvalid={disableFarming}
                        isValid={!disableFarming}
                        disabled={true}
                      />
                      <InputGroup.Append>
                        <RoundButton
                          size="small"
                          color="DARK"
                          onClick={setMaxToStake}
                        >
                          MAX
                        </RoundButton>
                      </InputGroup.Append>
                    </InputGroup>
                    <BalanceItem
                      image="/token-logos/logo.png"
                      title="Your Estimated Rewards"
                      balance={numberToCurrency(estimatedRewards)}
                      token="EvmosPad/month"
                      tooltipText={farmingTooltips.estimatedRewards}
                    />
                    <div className="stake-block__buttons">
                      <RoundButton
                        size="large"
                        disabled={allowance.isGreaterThanOrEqualTo(
                          numericToBalance(amountToStake, decimals)
                        )}
                        onClick={handleApprove}
                      >
                        Approve
                      </RoundButton>
                      <RoundButton
                        size="large"
                        disabled={disableFarming}
                        onClick={handleStake}
                      >
                        Stake
                      </RoundButton>
                    </div>
                    {/* <ApprovalSteps fillingCondition={allowance.isGreaterThanOrEqualTo(numericToBalance(amountToStake, decimals))} /> */}
                  </Tab>
                  <Tab
                    eventKey={FormTypes.UNSTAKE}
                    title={FormTypes.UNSTAKE.toLowerCase()}
                    className="stake-block-tab-unstake"
                    disabled={lastStakedInSeconds <= lockPeriod.toNumber()}
                  >
                    <div className="stake-form__heading">
                      <Image src={LPLogo} />
                      <div>
                        <span className="fw-bold">EvmosPad-EVMOS LP</span>
                      </div>
                    </div>
                    <InputGroup className="stake-form__input-group input-group-big">
                      <InputGroup.Prepend>Amount:</InputGroup.Prepend>
                      <FormControl
                        placeholder="0.0"
                        type="number"
                        inputMode="numeric"
                        value={amountToUnstake}
                        onChange={(e) => setAmountToUnstake(e.target.value)}
                        isInvalid={disableUnstaking}
                        isValid={!disableUnstaking}
                      />
                      <InputGroup.Append>
                        <RoundButton
                          size="small"
                          color="DARK"
                          onClick={setMaxToUnstake}
                        >
                          MAX
                        </RoundButton>
                      </InputGroup.Append>
                    </InputGroup>
                    <div className="stake-block__buttons">
                      <RoundButton
                        size="large"
                        disabled={!balanceToNumber(rewards)}
                        onClick={handleClaim}
                      >
                        Claim
                      </RoundButton>
                      <RoundButton
                        size="large"
                        disabled={disableUnstaking}
                        onClick={handleUnstake}
                      >
                        Unstake & Claim
                      </RoundButton>
                    </div>
                  </Tab>
                  <Tab
                    eventKey={FormTypes.STATS}
                    title={FormTypes.STATS.toLowerCase()}
                  >
                    <LoadingWrap loading={!totalStaked}>
                      {!!totalStaked && (
                        <dl className="info-list staking-stats">
                          <div>
                            <dt className="name">Total Staked</dt>
                            <dd className="value">
                              {balanceToCurrency(totalStaked, decimals)}
                              LP
                            </dd>
                          </div>
                          <div>
                            <dt className="name">Lock Period</dt>
                            <dd className="value">
                              {Math.ceil(lockPeriod.toNumber() / 86400)} days
                            </dd>
                          </div>
                        </dl>
                      )}
                    </LoadingWrap>
                  </Tab>
                </Tabs>
              </div>
            </Col>
            <Col md={{ span: 5 }}>
              <div className="stake-block__info">
                <BalanceItem
                  image="/token-logos/percent.png"
                  title="APY"
                  // balance={(rewardsAPY.toNumber() / 100).toString()}
                  balance={forceAPY}
                  token="%"
                  tooltipText={farmingTooltips.APY}
                />
                <BalanceItem
                  image="/token-logos/locked.png"
                  title="Amount staked"
                  balance={balanceToCurrency(staked, decimals)}
                  token="DEX LP"
                />
                <BalanceItem
                  image="/token-logos/logo.png"
                  title="Current rewards"
                  balance={balanceToCurrency(rewards, decimals)}
                  token="EvmosPad"
                  tooltipText={farmingTooltips.currentRewards}
                />
                <dl className="info-list">
                  <div className="info-list__item">
                    <dt className="name">
                      Last Stake
                      <CommonTooltip id="apy-tooltip" placement="bottom-start">
                        {farmingTooltips.lastStake}
                      </CommonTooltip>
                    </dt>
                    <dd className="value">
                      {lastStaked} <span className="text-big">day(s) ago</span>
                    </dd>
                  </div>
                  <div className="info-list__item">
                    <dt className="name">
                      Lock Period
                      <CommonTooltip id="apy-tooltip" placement="bottom-start">
                        {farmingTooltips.lockPeriod}
                      </CommonTooltip>
                    </dt>
                    <dd className="value">
                      {Math.ceil(lockPeriod.toNumber() / 86400)}{' '}
                      <span className="text-big">day(s)</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
