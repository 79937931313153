import { NotifyTxCallbacks, useNotify } from '../notify';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useMemo } from 'react';
import { TransactionConfig, TransactionReceipt } from 'web3-core';
import { useGasPrice } from './useGasPrice';
import { utils } from 'web3'
import { sendExceptionReport } from '../../utils/errors';
import { ProviderRpcError } from '../../constants';
import { BlockNumber } from 'web3-core'

const defaultGasLimit = 1000000
const gasMultiplier = parseFloat(process.env.REACT_APP_GAS_MULTIPLIER || '1.2')

export const useTransactions = () => {
  const { notifyWrapper } = useNotify()
  const { account } = useWeb3React()
  const { gasPrice } = useGasPrice()

  const txConfig = useMemo<TransactionConfig>(() => ({
    from: account || undefined,
    gasPrice: utils.toWei(String(gasPrice), 'gwei')
  }), [
    gasPrice,
    account
  ])

  const callTransaction = useCallback(async (
    txSignature: any,
    blockNumber: BlockNumber = 'latest'
  ) => {

    return await txSignature.call(
      { from: account || undefined },
      blockNumber
    )
  }, [account])

  const sendTransaction = useCallback(async (
    txSignature: any,
    callbacks: NotifyTxCallbacks = {}
  ): Promise<TransactionReceipt | ProviderRpcError> => {
    let gasLimit: number

    try {
      gasLimit = await txSignature.estimateGas(txConfig)
    } catch (err) {
      sendExceptionReport(err)
      gasLimit = defaultGasLimit
    }

    const finalGasLimit = Math.floor(gasLimit * gasMultiplier)

    const txCall = (txDetails: TransactionConfig) => txSignature.send(txDetails)
    return await notifyWrapper(
      txCall({
        ...txConfig,
        gas: finalGasLimit
      }),
      callbacks
    )
  }, [txConfig])

  const sendEthTransaction = useCallback(async (
    txSignature: any,
    value: any,
    callbacks: NotifyTxCallbacks = {}
  ): Promise<TransactionReceipt | ProviderRpcError> => {
    let gasLimit: number

    try {
      gasLimit = await txSignature.estimateGas({...txConfig, value})
    } catch (err) {
      sendExceptionReport(err)
      gasLimit = defaultGasLimit
    }

    const finalGasLimit = Math.floor(gasLimit * gasMultiplier)

    const txCall = (txDetails: TransactionConfig) => txSignature.send(txDetails)
    return await notifyWrapper(
      txCall({
        ...txConfig,
        gas: finalGasLimit,
        value
      }),
      callbacks
    )
  }, [txConfig])

  return {
    callTransaction,
    sendTransaction,
    sendEthTransaction
  }
}
