import Notify from 'bnc-notify';
import { TransactionReceipt } from 'web3-core'
import { autoDismissTime, ProviderRpcError } from '../constants';
import { networks, selectedNetwork } from './networks';
import { sendExceptionReport } from '../utils/errors';

export interface NotifyTxCallbacks {
  onHash?: () => void
  onReject?: () => void
  onSuccess?: () => void
  onError?: () => void
}

const networkScanUrl = networks[selectedNetwork].explorerUrl

export const notify = Notify({
  darkMode: true,
  desktopPosition: 'topRight'
})

export const useNotify = () => {

  const notifyWrapper = async (
    txMethodCall: any,
    {
      onHash,
      onReject,
      onSuccess,
      onError
    }: NotifyTxCallbacks = {}
  ) => {
    return new Promise<TransactionReceipt | ProviderRpcError>((resolve, reject) => {
      txMethodCall
        .on('transactionHash', (hash: string) => {
          notify.notification({
            type: 'pending',
            message: 'Transaction was sent to the network. Click to check it on network explorer.',
            onclick: () => {window.open(`${networkScanUrl}/tx/${hash}`, '_blank')},
            autoDismiss: autoDismissTime,
          })
          onHash && onHash()
        })
        .on('receipt', (receipt: TransactionReceipt) => {
          resolve(receipt);
          notify.notification({
            type: 'success',
            message: 'Transaction was confirmed. Click to see it on network explorer.',
            onclick: () => {window.open(`${networkScanUrl}/tx/${receipt.transactionHash}`, '_blank')},
            autoDismiss: autoDismissTime,
          })
          onSuccess && onSuccess()
        })
        .on('error', (error: ProviderRpcError, receipt?: TransactionReceipt) => {
          if (error.code === 4001) {
            notify.notification({
              type: 'error',
              message: 'You have rejected the transaction',
              autoDismiss: autoDismissTime,
            });
            onReject && onReject()
            return resolve(error);
          }

          if (receipt) {
            reject(error);
            notify.notification({
              type: 'error',
              message: 'Oops, something went wrong',
              autoDismiss: autoDismissTime,
            })
            onError && onError()
          }

          sendExceptionReport({ error, receipt })
        })
    })
  }

  return {
    notifyWrapper
  };
}
