import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { MetaMask } from '../connectors';

export const useEagerConnect = () => {
  const { activate } = useWeb3React();

  useEffect(() => {
    MetaMask.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(MetaMask, undefined, false)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // intentionally only running on mount (make sure it's only mounted once :))
};
