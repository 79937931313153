import React, { useMemo } from 'react';
import './ProjectStats.css';
import { ProjectStatusesProps, ProjectStatusProp, TokenName } from '../types';
import { useTimeLeft } from '../../../hooks/useTimeLeft';
import { Timer } from '../../Timer';
import { ProgressBar } from 'react-bootstrap';
import {
  differenceInDays,
  formatISO9075,
  formatDuration,
  intervalToDuration
} from 'date-fns';
import { numberToCurrency } from '../../../utils/balanceFormatter';
import classNames from 'classnames';
import { isDefined } from '../../../utils/object';
import { format } from 'path';
import { RoundButton } from '../../RoundButton';
import { useParams, generatePath } from 'react-router-dom';
import { RoutesPaths } from '../../../router/constants';
import { WhitelistStatuses } from '../../../contracts/hooks/useWhitelist';

export interface ProjectContractProps {
  totalRewards: number;
  fundsSwapped: number;
  totalAmount: number;
  swapExchangeRate: number;
}

export interface ProjectNonContractProps {
  fundTokenName: TokenName;
  rewardTokenName: TokenName;
  status: ProjectStatusProp;
  privateOpens?: Date | null;
  privateCloses?: Date | null;
  opens?: Date | null;
  closes?: Date | null;
  participants: number;
}

interface Props extends ProjectContractProps, ProjectNonContractProps {
  expanded: boolean;
  minAllocation?: number;
  maxAllocation?: number;
  id: string;
  whiteListStatus: WhitelistStatuses;
}

const ProjectStats = ({
  id,
  fundTokenName,
  rewardTokenName,
  status,
  privateOpens,
  privateCloses,
  opens,
  closes,
  totalAmount,
  fundsSwapped,
  swapExchangeRate,
  totalRewards,
  participants,
  expanded,
  minAllocation,
  maxAllocation,
  whiteListStatus
}: Props) => {
  const statsTitle = useMemo(() => {
    switch (status) {
      case ProjectStatusesProps['Registration Closed']:
      case ProjectStatusesProps['Registration Open']:
        return 'Starts in';
      case ProjectStatusesProps['Private Open']:
      case ProjectStatusesProps.Open:
        return 'In progress';
      default:
        return status;
    }
  }, [status]);

  const isStatusInProgress = useMemo(
    () =>
      [
        ProjectStatusesProps.Open,
        ProjectStatusesProps['Private Open']
      ].includes(status as ProjectStatusesProps),
    [status]
  );

  const hasPrivatePhase = useMemo(
    () => isDefined(privateOpens) && isDefined(privateCloses),
    [privateOpens, privateCloses]
  );

  const hasPublicPhase = useMemo(() => isDefined(opens) && isDefined(closes), [
    opens,
    closes
  ]);

  const startsIn = useTimeLeft(isDefined(privateOpens) ? privateOpens : opens);
  const progressRange = useMemo(() => {
    return Math.round((fundsSwapped / (totalAmount ?? 1)) * 100);
  }, [fundsSwapped, totalAmount]);

  return (
    <div className={classNames('project-stats', { tile: expanded })}>
      {expanded && (
        <div className="project-stats__status">
          <h3 className="title">{statsTitle}</h3>
          {!!startsIn &&
            status !== ProjectStatusesProps.Closed &&
            isStatusInProgress && <Timer duration={startsIn} />}
        </div>
      )}

      <dl className="project-stats__info">
        {status !== ProjectStatusesProps['Coming Soon'] && !!totalRewards && (
          <>
            <div className="stats-block">
              <dt className="name">Total Swap Amount</dt>
              <dd className="value">{`${numberToCurrency(
                totalRewards
              )} ${rewardTokenName}`}</dd>
            </div>
            <div className="stats-block">
              <dt className="name">Swap Rate</dt>
              <dd className="value">{`1 ${rewardTokenName} = ${numberToCurrency(
                swapExchangeRate,
                10
              )} ${fundTokenName}`}</dd>
            </div>
          </>
        )}
        {!!(minAllocation || maxAllocation) && (
          <>
            <div className="stats-block">
              <dt className="name">Min Allocation Amount</dt>
              <dd className="value">
                {minAllocation
                  ? `${numberToCurrency(minAllocation)} ${fundTokenName}`
                  : '-'}
              </dd>
            </div>
            <div className="stats-block">
              <dt className="name">Max Allocation Amount</dt>
              <dd className="value">
                {maxAllocation
                  ? `${numberToCurrency(maxAllocation)} ${fundTokenName}`
                  : '-'}
              </dd>
            </div>
          </>
        )}
        {hasPrivatePhase && (
          <div className="stats-block stats-block--double">
            <div>
              <dt className="name">Private Opens</dt>
              <dd className="value">
                {privateOpens
                  ? privateOpens?.getTime() >= new Date().getTime()
                    ? formatDuration(
                        intervalToDuration({
                          start: privateOpens,
                          end: new Date()
                        })
                      )
                    : formatISO9075(privateOpens)
                  : 'TBA'}
              </dd>
            </div>
            {/* <div className='separator'/> */}
            <div>
              <dt className="name">Private Closes</dt>
              <dd className="value">
                {privateCloses
                  ? privateCloses?.getTime() >= new Date().getTime()
                    ? formatDuration(
                        intervalToDuration({
                          start: privateCloses,
                          end: new Date()
                        })
                      )
                    : formatISO9075(privateCloses)
                  : 'TBA'}
              </dd>
            </div>
          </div>
        )}
        {hasPublicPhase && (
          <div className="stats-block stats-block--double">
            <div>
              <dt className="name">Public Opens</dt>
              <dd className="value">
                {opens
                  ? opens?.getTime() >= new Date().getTime()
                    ? formatDuration(
                        intervalToDuration({ start: opens, end: new Date() })
                      )
                    : formatISO9075(opens)
                  : 'TBA'}
              </dd>
            </div>
            {/* <div className='separator'/> */}
            <div>
              <dt className="name">Public Closes</dt>
              <dd className="value">
                {closes
                  ? closes?.getTime() >= new Date().getTime()
                    ? formatDuration(
                        intervalToDuration({ start: closes, end: new Date() })
                      )
                    : formatISO9075(closes)
                  : 'TBA'}
              </dd>
            </div>
          </div>
        )}
      </dl>
      {(expanded || isStatusInProgress) && (
        <>
          <div className="project-stats__progress">
            {!!fundsSwapped && (
              <span className="percentage">{progressRange} %</span>
            )}
            <ProgressBar now={progressRange} />
          </div>
          {status !== ProjectStatusesProps['Coming Soon'] && !!totalRewards && (
            <dl className="project-stats__list info-list">
              <div>
                <dt className="name">Amount Raised</dt>
                <dd className="value">{`${numberToCurrency(
                  fundsSwapped,
                  0
                )}/${numberToCurrency(totalAmount, 0)} ${fundTokenName}`}</dd>
              </div>
              {participants > 0 && (
                <div>
                  <dt className="name">Participants </dt>
                  <dd className="value">{participants}</dd>
                </div>
              )}
            </dl>
          )}
        </>
      )}

      {expanded &&
        status === ProjectStatusesProps['Registration Open'] &&
        (!whiteListStatus ||
          whiteListStatus === WhitelistStatuses.not_submitted) && (
          <div className="apply-white-list">
            <RoundButton
              color="DARK"
              to={generatePath(RoutesPaths.WHITELIST, { id })}
            >
              Apply to Whitelist
            </RoundButton>
          </div>
        )}
    </div>
  );
};

ProjectStats.defaultProps = {
  totalAmount: 0,
  fundsSwapped: 0,
  totalRewards: 0,
  participants: 0,
  privateOpens: undefined,
  privateCloses: undefined,
  opens: undefined,
  closes: undefined,
  expanded: false
};

export { ProjectStats };
