import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  TimerWrap,
  ProjectsList,
  LoadingWrap,
  PortfolioList,
} from '../../components';
import { dueDateForProjects } from '../../constants';
import './Projects.css';
import { useProjectsState } from '../../contracts/hooks/useProjects';

export const Projects = () => {
  const {
    loading,
    openProjects,
    upcomingProjects,
    completedProjects,
    getProjects
  } = useProjectsState()

  useEffect(() => {
    getProjects()
  }, [])

  return (
    <div className="projects-page page">
      <section className="project-top">
        <Container className="py-5">
        </Container>
      </section>
      <section className="projects-open">
        <Container>
          <h1 className="title mb-5">Projects Open Now</h1>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {
                openProjects.length
                  ? <ProjectsList projects={openProjects} />
                  : (
                    <Col md={{ span: 12 }}>
                      <div className="no-projects-tile">
                        <p className="tile__description">
                          No project currently open
                        </p>
                      </div>
                    </Col>
                  )
              }
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <TimerWrap dueDate={dueDateForProjects}>
        <section className="projects-upcoming">
          <Container>
            <h1 className="title mb-5">Projects Coming Soon</h1>
            <Row className="g-4">
              <LoadingWrap loading={loading}>
                {
                  upcomingProjects.length
                    ? <ProjectsList projects={upcomingProjects} />
                    : (
                      <Col md={{ span: 12 }}>
                        <div className="no-projects-tile">
                          <p className="tile__description">
                            No upcoming projects now
                          </p>
                        </div>
                      </Col>
                    )
                }
              </LoadingWrap>
            </Row>
          </Container>
        </section>
      </TimerWrap>
    </div>
  );
};
