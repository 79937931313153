import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { useTokenContract } from './useContract';
import { maxUint256String } from '../../utils/web3';
import { NotifyTxCallbacks } from '../notify';
import BigNumber from 'bignumber.js';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '../../hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';

export const useApproval = (tokenAddress?: string, lockingstring?: string) => {
  const isMountedRef = useIsMounted()
  const { account } = useWeb3React();
  const tokenContract = useTokenContract(tokenAddress);
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
  const {
    callTransaction,
    sendTransaction
  } = useTransactions()

  const fetchAllowance = useCallback(async (blockNumber?: BlockNumber) => {
    if (tokenAddress === "0x0000000000000000000000000000000000000000") {
      setAllowance(new BigNumber("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"))
      return
    }

    if (!account || !tokenContract) {
      setAllowance(new BigNumber(0))
      return
    }

    const res = await callTransaction(
      tokenContract?.methods.allowance(account, lockingstring),
      blockNumber
    )

    isMountedRef.current && setAllowance(new BigNumber(res))
  }, [account, tokenContract, lockingstring, isMountedRef])

  useEffect(() => {
    if (account && tokenContract) {
      fetchAllowance();
    }
  }, [account, tokenContract])

  const handleApprove = useCallback(async (
    amount = maxUint256String,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    const receipt = await sendTransaction(
      tokenContract?.methods.approve(
        lockingstring,
        amount
      ),
      callbacks
    ) as TransactionReceipt

    await fetchAllowance(receipt.blockNumber)
  }, [tokenContract, lockingstring, sendTransaction])

  return {
    allowance,
    onApprove: handleApprove,
  }
}
