import React from 'react';
import { PortfolioItem } from '../PortfolioItem';
import './PortfolioList.css'
import { NormalizedProjectInfo } from '../../Project/types';
import { LoadingWrap } from '../../';

interface Props {
  projects?: NormalizedProjectInfo[]
}

export const PortfolioList = ({ projects }: Props) => {

  return (
    <div className="portfolio-list">
        <ul className='portfolio-list__header'>
          <li>Project</li>
          <li>Total</li>
          <li>Unvested</li>
          <li>Claimed</li>
          <li>Claimable</li>
          <li>Action</li>
        </ul>
      <div className='portfolio-list__body'>
        {
          !!projects && projects.length
          ? projects.map(project => (
              <PortfolioItem key={project.id} project={project} />
            )
            ) : (
              <div className="tile--with-shadow no-projects-tile">
                Did you participate in any presale?
              </div>
            )
        }
      </div>
    </div>
  )
}
