import React, { useEffect } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import {
  Projects,
  IndividualProject,
  Staking,
  ProjectsClosed,
  Claim,
  Farming,
  Whitelist,
  Wallet
} from '../pages';
import {
  Header,
  ScrollRestoration,
  ScrollToTop,
  WrongNetworkModal
} from '../components';
import { useEagerConnect } from '../hooks/useEagerConnect';
import { useWeb3React } from '@web3-react/core';
import { useGasPrice } from '../contracts/hooks/useGasPrice';
import { RoutesPaths } from './constants';
import ParticlesBg from 'particles-bg';
import { BitHotel } from '../pages/BitHotel';

const Routes = () => {
  useEagerConnect();
  const { account, error } = useWeb3React();
  const { getGasPrice } = useGasPrice();

  let gasPriceListener: ReturnType<typeof setInterval>;

  useEffect(() => {
    getGasPrice();
    gasPriceListener = setInterval(getGasPrice, 30000);

    return () => clearInterval(gasPriceListener);
  }, []);

  return (
    <HashRouter>
      <div style={{ height: '100vh', overflow: 'auto' }}>
        <ScrollRestoration>
          <Header />
          <Switch>
            <Route exact path={RoutesPaths.MAIN} component={Projects} />
            {/* <Route exact path={RoutesPaths.BITHOTEL} component={BitHotel} /> */}
            <Route exact path={RoutesPaths.PROJECTS} component={Projects} />
            <Route
              exact
              path={RoutesPaths.COMPLETED}
              component={ProjectsClosed}
            />
            <Route exact path={RoutesPaths.WHITELIST} component={Whitelist} />

            <Route exact path={RoutesPaths.CLAIM} component={Claim} />
            <Route
              exact
              path={RoutesPaths.PROJECT}
              component={IndividualProject}
            />
            <Route
              exact
              path={RoutesPaths.STAKING}
              component={account ? Staking : Wallet}
            />
            <Route
              exact
              path={RoutesPaths.FARMING}
              component={account ? Farming : Wallet}
            />
            <Route path="/*" component={Projects} />
          </Switch>
          <ScrollToTop container={window} />
          <WrongNetworkModal error={error} />
        </ScrollRestoration>
      </div>
      {/* <ParticlesBg num={10} type="circle" bg={true} /> */}
    </HashRouter>
  );
};
export default Routes;
