import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { RoutesPaths } from '../../router/constants';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { format, formatISO9075 } from 'date-fns';
import {
  BackLink,
  DynamicImage,
  LoadingWrap,
  ProjectDescription,
  RoundButton,
  WhitelistForm,
  WhitelistStatusView
} from '../../components';
import { ProjectBio } from '../../components/Project/ProjectBio';
// import { useFlameTier } from '../../contracts/hooks/useFlameTier/useFlameTier';
import {
  useWhitelist,
  WhitelistStatuses
} from '../../contracts/hooks/useWhitelist';
import { supportEmail } from '../../constants';
import './Whitelist.css';
import { useWeb3React } from '@web3-react/core';
import { useProjectsState } from '../../contracts/hooks/useProjects';
import { ProjectStatusesProps } from '../../components/Project/types';
import { projectGetters } from '../../contracts/getters/projectGetters';

interface ParamTypes {
  id: string;
}

export const Whitelist = () => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const {
    loading,
    currentProject: project,
    getProjectById
  } = useProjectsState();
  console.log('---------', project);

  useEffect(() => {
    if (id !== project?.id || !project) {
      getProjectById(id);
    }
  }, [id, project]);

  console.log('-----', project);

  // useEffect(() => {
  //   if (
  //     project &&
  //     project.presale.statusMessage !==
  //       ProjectStatusesProps['Registration Open']
  //   ) {
  //     history.replace(`/projects/${id}`);
  //   }
  // }, [project, id]);

  const { whiteListStatus, applyToWhitelist } = useWhitelist(
    id,
    project?.presale.whitelist_contract_address
  );
  const { account } = useWeb3React();
  // const { userTierInfo, loading: loadingTier } = useFlameTier();

  return (
    <div className="whitelist-page page">
      <Container className="py-5">
        <h1 className="title mb-0">
          {!!project && projectGetters.getProjectTypeSuffix(project)} Whitelist
        </h1>
      </Container>
      <LoadingWrap loading={!whiteListStatus || loading}>
        {!!project &&
          !!project.whitelisting.starts_at &&
          whiteListStatus === WhitelistStatuses.not_submitted && (
            <section className="whitelist-section">
              <Container>
                <Row>
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    <BackLink to={generatePath(RoutesPaths.PROJECT, { id })}>
                      Back to Campaign
                    </BackLink>
                    <ProjectDescription
                      logo={project.assets.logo_image_url}
                      name={project.name}
                      projectType={project.project_type}
                      subtitle={project.info.subtitle}
                      status={project.presale.statusMessage}
                      network={project.network}
                      fundTokenName={project.presale.fund_token.name}
                      description={project.info.description}
                      socialLinks={project.social_links}
                    />
                    {!!project.info.bio && <ProjectBio {...project.info.bio} />}
                    <div className="whitelist-time tile">
                      <h2 className="title">Time Frame</h2>
                      <dl className="info-list">
                        <div>
                          <dt className="name">Whitelist opens</dt>
                          <dd className="value">
                            {formatISO9075(project.whitelisting.starts_at)}
                          </dd>
                        </div>
                        {!!project.whitelisting.end_at && (
                          <div>
                            <dt className="name">Whitelist closes</dt>
                            <dd className="value">
                              {formatISO9075(project.whitelisting.end_at)}
                            </dd>
                          </div>
                        )}
                        {!!project.presale.starts_at && (
                          <div>
                            <dt className="name">Public offering date</dt>
                            <dd className="value">
                              {format(project.presale.starts_at, 'MM/dd/yyyy')}
                            </dd>
                          </div>
                        )}
                      </dl>
                    </div>

                    <div className="whitelist-help tile">
                      <p className="text-big fw-semibold">
                        Need some help with EclipsePad?
                      </p>
                      <RoundButton
                        href={`mailto:${supportEmail}`}
                        size="large"
                        color="DARK"
                      >
                        Get Help
                      </RoundButton>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    xl={{ span: 6 }}
                    className="mt-5 mt-xl-0"
                  >
                    <WhitelistForm
                      project={project}
                      whitelistInfo={project.whitelisting}
                      onSubmit={applyToWhitelist}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {whiteListStatus &&
          whiteListStatus !== WhitelistStatuses.not_submitted && (
            <>
              <Container>
                <BackLink to={generatePath(RoutesPaths.PROJECT, { id })}>
                  Back to Campaign
                </BackLink>
              </Container>
              <WhitelistStatusView status={whiteListStatus} />
            </>
          )}
      </LoadingWrap>
    </div>
  );
};
