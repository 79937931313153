import { WhitelistStatus } from '../../contracts/hooks/useWhitelist';
import { instanceWithSignature as API } from '../config';
import { getWhitelistArgs, WhitelistSuccessResponse } from './types';
import { AxiosError } from 'axios';
import { sendExceptionReport } from '../../utils/errors';

export const getWhitelistStatus = async ({ account, projectId }: getWhitelistArgs): Promise<WhitelistStatus | null> => {
  try {
    const { data } = await API.get('/get-whitelist-data', {
      params: {
        wallet_address: account,
        project_id: projectId
      }
    })

    return (data as WhitelistSuccessResponse).status
  } catch (err) {
    if ((err as AxiosError).response?.status !== 404) {
      sendExceptionReport(err)
    }
    return null
  }
}
