import React, { useMemo, useState } from 'react';
import './WhitelistForm.css';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { RoundButton } from '../../RoundButton';
import {
  NormalizedProjectInfo,
  NormalizedWhitelistingInfo,
  WhitelistAllowances
} from '../../Project/types';
import classNames from 'classnames';
// import { WhitelistRequestFormData } from '../../../api/whitelist/types';
import { Spinner } from '../../Spinner';
import {
  projectGetters
  // whitelistGetters
} from '../../../contracts/getters/projectGetters';
import { balanceToCurrency } from '../../../utils/balanceFormatter';
import { CommonTooltip } from '../../CommonTooltip';
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';
// import { useFlameTier } from '../../../contracts/hooks/useFlameTier/useFlameTier';
// import { FLAME_DECIMALS, FLAME_USDC_LP_DECIMALS } from '../../../constants';

export interface WLFormValues {
  email: string;
  telegram_link: string | null;
  twitter_link: string | null;
  follow_twitter?: boolean;
  follow_telegram?: boolean;
  follow_discord?: boolean;
  agree_to_saft?: boolean;
}

const twitterNameRegex = /^[A-Za-z0-9_]{1,15}$/;

const getValidationSchema = (project: NormalizedProjectInfo) => {
  return yup.object().shape({
    ...{
      email: yup
        .string()
        .trim()
        .email('Write email in correct format')
        .required('Email is required')
    },
    ...{
      telegram_link: yup.string().trim().optional()
    },
    ...{
      twitter_link: yup
        .string()
        .trim()
        .matches(twitterNameRegex, {
          message:
            'Username can contain only latin letters, numbers and underscores',
          excludeEmptyString: true
        })
        .optional()
    }
  });
};

interface Props {
  project: NormalizedProjectInfo;
  whitelistInfo: NormalizedWhitelistingInfo;
  onSubmit: (formData: any) => Promise<unknown> | unknown;
}

export const WhitelistForm = ({ project, whitelistInfo, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  const isEmailRequired = true;
  const isTelegramRequired = false;
  const isTwitterRequired = false;
  const isFollowDSRequired = false;
  const isFollowTwitterRequired = false;
  const isFollowTelegramRequired = false;
  const whitelistAllowances = false;

  const initialValues: WLFormValues = {
    email: '',
    telegram_link: '',
    twitter_link: '',
    ...(isFollowTwitterRequired
      ? {
          follow_twitter: false
        }
      : {}),
    ...(isFollowTelegramRequired
      ? {
          follow_telegram: false
        }
      : {}),
    ...(isFollowDSRequired
      ? {
          follow_discord: false
        }
      : {})
  };

  const allowWhitelist = true;

  const onSubmitHandler = async (
    values: WLFormValues,
    formikHelpers: FormikHelpers<WLFormValues>
  ) => {
    if (loading) {
      return;
    }
    setFailed(false);
    setLoading(true);
    const status = await onSubmit({
      ...values,
      telegram_link: values.telegram_link
        ? `https://t.me/${values.telegram_link}`
        : '',
      twitter_link: values.twitter_link
        ? `https://twitter.com/${values.twitter_link}`
        : ''
    });
    setLoading(false);
    if (status) {
      formikHelpers.resetForm({ values: { ...initialValues } });
    } else {
      setFailed(true);
    }
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isValid,
    getFieldProps,
    setFieldValue
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: getValidationSchema(project),
    onSubmit: onSubmitHandler,
    initialValues
  });

  return (
    <Form noValidate onSubmit={handleSubmit} className="whitelist-form tile">
      <h2 className="title">
        Apply to {projectGetters.getProjectTypeSuffix(project)} Whitelist
      </h2>
      <Form.Group controlId="email">
        <Form.Label>Email address {isEmailRequired && '*'}</Form.Label>
        <Form.Control
          type="email"
          placeholder="username@email.com"
          {...getFieldProps('email')}
          isValid={touched.email && !errors.email}
          isInvalid={touched.email && !!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="telegram_link">
        <Form.Label>
          Telegram profile link {isTelegramRequired && '*'}
        </Form.Label>
        <InputGroup
          className={classNames({
            'is-invalid': touched.telegram_link && !!errors.telegram_link
          })}
        >
          <InputGroup.Prepend>https://t.me/</InputGroup.Prepend>
          <Form.Control
            type="url"
            placeholder="username"
            {...getFieldProps('telegram_link')}
            isValid={touched.telegram_link && !errors.telegram_link}
            isInvalid={touched.telegram_link && !!errors.telegram_link}
          />
        </InputGroup>
        <Form.Control.Feedback type="invalid">
          {errors.telegram_link}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="twitter_link">
        <Form.Label>Twitter profile link {isTwitterRequired && '*'}</Form.Label>
        <InputGroup
          className={classNames({
            'is-invalid': touched.twitter_link && !!errors.twitter_link
          })}
        >
          <InputGroup.Prepend>https://twitter.com/</InputGroup.Prepend>
          <FormControl
            type="text"
            placeholder="username"
            {...getFieldProps('twitter_link')}
            isValid={touched.twitter_link && !errors.twitter_link}
            isInvalid={touched.twitter_link && !!errors.twitter_link}
          />
        </InputGroup>
        <Form.Control.Feedback type="invalid">
          {errors.twitter_link}
        </Form.Control.Feedback>
      </Form.Group>
      {isFollowTwitterRequired && (
        <Form.Row>
          <Form.Group controlId="follow_twitter">
            <Form.Check
              label={`Follow ${project.name} on Twitter`}
              {...getFieldProps('follow_twitter')}
              isInvalid={touched.follow_twitter && !!errors.follow_twitter}
            />
          </Form.Group>
          <RoundButton
            href={whitelistInfo.fields.follow_twitter_url as string}
            color="DARK"
          >
            Open on twitter
          </RoundButton>
        </Form.Row>
      )}
      {isFollowTelegramRequired && (
        <Form.Row>
          <Form.Group controlId="follow_telegram">
            <Form.Check
              label={`Follow ${project.name} on Telegram`}
              {...getFieldProps('follow_telegram')}
              isInvalid={touched.follow_telegram && !!errors.follow_telegram}
            />
          </Form.Group>
          <RoundButton
            href={whitelistInfo.fields.follow_telegram_url as string}
            color="DARK"
          >
            Open on telegram
          </RoundButton>
        </Form.Row>
      )}
      {isFollowDSRequired && (
        <Form.Row>
          <Form.Group controlId="follow_discord">
            <Form.Check
              label={`Follow ${project.name} on Discord`}
              {...getFieldProps('follow_discord')}
              isInvalid={touched.follow_discord && !!errors.follow_discord}
            />
          </Form.Group>
          <RoundButton
            href={whitelistInfo.fields.follow_discord_url as string}
            color="DARK"
          >
            Open on discord
          </RoundButton>
        </Form.Row>
      )}

      <RoundButton
        type="submit"
        size="large"
        disabled={!isValid || loading || !allowWhitelist}
      >
        {loading ? <Spinner /> : <span>Send</span>}
      </RoundButton>
      {failed && (
        <div className="form-message form-message--warning text-center mt-3">
          <InfoIcon />
          <span>
            Oops! Your application was not submitted. Check your data and try
            again
          </span>
        </div>
      )}
    </Form>
  );
};
