import { NetworkName } from '../../contracts/networks';

export enum ProjectStatuses {
  private =  'private',
  coming_soon = 'coming_soon',
  active = 'active',
  completed = 'completed',
}

export enum ProjectTypes {
  token_presale = 'token_presale',
  NFT_presale = 'NFT_presale',
  NFT_claim = 'NFT_claim',
}

export type ProjectType = keyof typeof ProjectTypes

export type ProjectStatus = keyof typeof ProjectStatuses

export enum ProjectStatusesProps {
  "Coming Soon" = 'Coming Soon',
  "Registration Open" = 'Registration Open',
  "Registration Closed" = 'Registration Closed',
  "Private Open" = 'Private Open',
  Open = 'Open',
  Closed = 'Closed',
}

export type ProjectStatusProp = keyof typeof ProjectStatusesProps

export enum WhitelistAllowances {
  all = 'all', // allow whitelisting for all KYC'd users
  locked = 'locked', // allow whitelisting for users with locked tokens
}

export type WhitelistAllowance = keyof typeof WhitelistAllowances

export enum SocialMedias {
  web = 'web',
  twitter = 'twitter',
  telegram = 'telegram',
  medium = 'medium',
  discord = 'discord',
  instagram = 'instagram',
}

export type SocialMedia = keyof typeof SocialMedias

export type TokenName = string

export interface TokenInfo {
  name: TokenName
  address?: string
  icon?: string
}

export interface SocialLink {
  name: SocialMedia
  url: string
}

export interface DatesInterval {
  starts_at: string | null
  end_at: string | null
}

export interface PresaleDates {
  starts_at_display?: string | null
  starts_at?: string | null
  end_at?: string | null
  private_starts_at?: string | null
  private_end_at?: string | null
}

type NormalizeDates<T extends object> = {
  [K in keyof T]: Date | null
}

export type NormalizedDatesInterval = NormalizeDates<DatesInterval>
export type NormalizedPresaleDates = NormalizeDates<PresaleDates>

export interface WhitelistingFields {
  email_required: boolean
  telegram_required: boolean
  twitter_required: boolean
  follow_twitter_url: string | null
  follow_telegram_url: string | null
  follow_discord_url: string | null
}

export interface WhitelistingInfo extends DatesInterval {
  country_restrictions: string[]
  fields: WhitelistingFields
}

export interface NormalizedWhitelistingInfo extends
  Omit<WhitelistingInfo, 'starts_at' | 'end_at'>,
  NormalizedDatesInterval
{}

export interface PresaleInfo extends PresaleDates {
  min_allocation?: number
  max_allocation?: number
  presale_contract_address?: string
  vesting_contract_address?: string
  whitelist_contract_address: string
  fund_token: TokenInfo
  reward_token: TokenInfo
  status: ProjectStatus
  funds_swapped?: string
  total_swap_amount?: string
  total_rewards_amount?: string
  swap_rate?: string
  require_memo?: boolean
  memo_name?: string
}

export interface NormalizedPresaleInfo extends
  Omit<PresaleInfo, 'starts_at_display' | 'starts_at' | 'end_at' | 'private_starts_at' | 'private_end_at'>,
  NormalizedPresaleDates
{
  statusMessage: ProjectStatusProp
}

export interface ProjectBio {
  title: string
  image?: string
  text: string
}

export interface ProjectInfo {
  subtitle?: string
  description: string
  main_color: string
  bio?: ProjectBio
}

export interface ProjectAssets {
  logo_image_url: string
  nft_image_url?: string
}

export interface ProjectResponseInfo {
  masterTag?: string
  tag?: string
  id: string
  name: string
  network: NetworkName
  chainId: number | string
  project_type: ProjectType
  info: ProjectInfo
  assets: ProjectAssets
  social_links: SocialLink[]
  whitelisting: WhitelistingInfo
  presale: PresaleInfo
  created_at?: number
  updated_at?: number
}

export interface NormalizedProjectInfo extends
  Omit<ProjectResponseInfo, 'presale' | 'whitelisting'>
{
  whitelisting: NormalizedWhitelistingInfo
  presale: NormalizedPresaleInfo
}
