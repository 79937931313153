import React, { useState } from 'react';
import './PartnerPassword.css'
import { Form, Image } from 'react-bootstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { RoundButton } from '../RoundButton';

interface Props {
  logo: string
  name: string
  description: string
  passwordError: string | null
  onSubmit: (password: string) => void | Promise<void>
}

interface FormValues {
  password: string
}

const initialValues = {
  password: ''
}

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is required')
})

export const PartnerPassword = ({
  logo,
  name,
  description,
  passwordError,
  onSubmit
}: Props) => {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async ({ password }: FormValues) => {
    if (loading) {
      return
    }

    setLoading(true)
    await onSubmit(password)
    setLoading(false)
  }

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    getFieldProps,
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues,
  })

  return (
    <div className="partner-pwd-widget tile">
      <Image
        className='partner-pwd-widget__logo'
        roundedCircle
        src={logo}
      />
      <h2
        className='partner-pwd-widget__title tile__main'
        dangerouslySetInnerHTML={{ __html: name }}
      />
      <div
        className='partner-pwd-widget__description'
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Form
        className="partner-pwd-form"
        noValidate
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="password">
          <Form.Control
            type="password"
            placeholder="Password"
            {...getFieldProps('password')}
            isValid={touched.password && !errors.password}
            isInvalid={(touched.password && !!errors.password) || !!passwordError}
          />
          <Form.Control.Feedback type="invalid">{passwordError || errors.password}</Form.Control.Feedback>
        </Form.Group>
        <RoundButton
          className="partner-pwd-form__button"
          type="submit"
          size="large"
          disabled={!isValid || loading}
        >
          Continue
        </RoundButton>
      </Form>
    </div>
  )
}
