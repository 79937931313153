import { useProjectPresaleContract, useTokenContract } from './useContract';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { NotifyTxCallbacks } from '../notify';
import { sendExceptionReport } from '../../utils/errors';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '../../hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';
import { useDecimals, useTokenBalance } from './useBalances';

export const usePresale = (presaleAddress?: string, fundTokenAddress?: string, rewardTokenAddress?: string) => {
  const isMountedRef = useIsMounted()
  const fundTokenContract = useTokenContract(fundTokenAddress)
  const presaleContract = useProjectPresaleContract(presaleAddress)
  const { account } = useWeb3React()

  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const fundTokenBalance = useTokenBalance(fundTokenAddress, loading, blockNumber)
  const [privateSoldAmount, setPrivateSoldAmount] = useState<BigNumber>(new BigNumber(0))
  const [publicSoldAmount, setPublicSoldAmount] = useState<BigNumber>(new BigNumber(0))
  const [totalRewardsAmount, setTotalRewardsAmount] = useState<BigNumber>(new BigNumber(0))
  const [exchangeRate, setExchangeRate] = useState<BigNumber>(new BigNumber(1))
  const [accuracy, setAccuracy] = useState<BigNumber>(new BigNumber(10))
  const [swappedByUser, setSwappedByUser] = useState<BigNumber>(new BigNumber(0))
  const [swappedByUserPrivate, setSwappedByUserPrivate] = useState<BigNumber>(new BigNumber(0))
  const [participants, setParticipants] = useState(0)
  const [userMemo, setUserMemo] = useState('')

  const fundsDecimals = useDecimals(fundTokenAddress);
  const rewardsDecimals = useDecimals(rewardTokenAddress);
  const precisionDiff = useMemo(() => {
    return rewardsDecimals - fundsDecimals;
  }, [rewardsDecimals, fundsDecimals]);

  const {
    callTransaction,
    sendTransaction,
    sendEthTransaction
  } = useTransactions()

  const swapExchangeRate = useMemo(() => {
    return exchangeRate.dividedBy(accuracy)
  }, [exchangeRate, accuracy])

  const fundsSwapped = useMemo(() => {
    return privateSoldAmount
      .plus(publicSoldAmount)
      .times(swapExchangeRate)
      .dividedBy(new BigNumber(10).pow(precisionDiff))
  }, [privateSoldAmount, publicSoldAmount, swapExchangeRate, precisionDiff])

  const totalSwapAmount = useMemo(() => {
    return totalRewardsAmount
      .times(swapExchangeRate)
      .dividedBy(new BigNumber(10).pow(precisionDiff))
  }, [totalRewardsAmount, swapExchangeRate, precisionDiff])

  const resetInfo = () => {
    setTotalRewardsAmount(new BigNumber(0))
    setAccuracy(new BigNumber(10))
    setExchangeRate(new BigNumber(1))
  }

  const fetchInfo = useCallback(async () => {
    if (!presaleContract || !fundTokenContract) {
      resetInfo()
      return
    }

    try {
      const initialRewards = await presaleContract.methods.initialRewardAmount().call()
      const rate = await presaleContract.methods.exchangeRate().call()
      const rateDecimals = await presaleContract.methods.ACCURACY().call()

      if (isMountedRef.current) {
        setTotalRewardsAmount(new BigNumber(initialRewards))
        setAccuracy(new BigNumber(rateDecimals))
        setExchangeRate(new BigNumber(rate))
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetInfo()
    }
  }, [presaleContract, fundTokenContract, isMountedRef])

  useEffect(() => {
    fetchInfo()
  }, [presaleContract, fundTokenContract])

  const resetActualInfo = () => {
    setPrivateSoldAmount(new BigNumber(0))
    setPublicSoldAmount(new BigNumber(0))
    setSwappedByUser(new BigNumber(0))
    setSwappedByUserPrivate(new BigNumber(0))
    setParticipants(0)
  }

  const fetchActualInfo = useCallback(async () => {
    if (!account || !presaleContract || !fundTokenContract) {
      resetActualInfo()
      return
    }

    try {
      const publicSold = await callTransaction(
        presaleContract.methods.publicSoldAmount(),
        blockNumber
      )
      const privateSold = await callTransaction(
        presaleContract.methods.privateSoldAmount(),
        blockNumber
      )
      const privateSoldFunds = await callTransaction(
        presaleContract.methods.privateSoldFunds(account),
        blockNumber
      )
      const { ftBalance } = await callTransaction(
        presaleContract.methods.recipients(account),
        blockNumber
      )
      const { memo } = await callTransaction(
        presaleContract.methods.recipients(account),
        blockNumber
      )
      const participantsCount = await callTransaction(
        presaleContract.methods.participantCount(),
        blockNumber
      )

      if (isMountedRef.current) {
        if (presaleAddress === "0x117cD1B5F0A0A60Adc0247a1E2734BAc920628bf") {
          setPublicSoldAmount(new BigNumber("9259260000000000000000000"));
          setParticipants(60);
        } else if (presaleAddress === "0x04Bb8660d11c5a7901cCc26D3396258e19332aa6") {
          setPublicSoldAmount(new BigNumber("200000000000000000000000"));
          setParticipants(113);
        } else if (presaleAddress === "0x7908E8fdeb68B6733B8bf7A362518039C48B01a5") {
          setPublicSoldAmount(new BigNumber("2222222222222222222222222"));
          setParticipants((+participantsCount) as number + 40);
        } else if (presaleAddress === "0x8f13A043bb68287dfb913eC7D826aEdf6De18e5F") {
          setPublicSoldAmount(new BigNumber("6250000000000000000000000"));
          setParticipants(260);
        } else if (presaleAddress === "0xbC5169E0Ed52CC2787C9aa41cbBd8CA9851075Bd") {
          setPublicSoldAmount(new BigNumber("30000000000000000000000000"));
          setParticipants((+participantsCount) as number + 38 + 1);
        } else if (presaleAddress === "0x5410fF0D6e054Ae41A7Eac7ecc130cF7a0F2Bdc3") {
          setPublicSoldAmount(new BigNumber("375000000000000000000000"));
          setParticipants((+participantsCount) as number + 134);
        } else if (presaleAddress === "0xa29ACAfAD04C5AF44285028F3890977B00d226f2") {
          setPublicSoldAmount(new BigNumber("2000000000000000000000000"));
          setParticipants(40);
        } else if (presaleAddress === "0x9DC3fb3463ec0c26c5fb68d776b5C0dE31A957E4") {
          setPublicSoldAmount(new BigNumber(publicSold).plus(new BigNumber("3246944444444444444444444")));
          setParticipants(+participantsCount as number + 37);
        } else if (presaleAddress === "0x335D3999bBdf3B9e71a81BB96B657E94291C0815") {
          setPublicSoldAmount(new BigNumber(publicSold).plus(new BigNumber("7550000000000000000000000")));
          setParticipants(+participantsCount as number + 14);
        } else if (presaleAddress === "0xBd4a0a855e2087557113f0542dE47655DA368c27") {
          setPublicSoldAmount(new BigNumber("5000000000000000000000000"));
          setParticipants(+participantsCount as number + 13);
        } else if (presaleAddress === "0x772cadA211fEE78Afb4ff82D0714eA8973032d89") {
          setPublicSoldAmount(new BigNumber("30000000000000000000000000"));
          setParticipants(+participantsCount as number + 36);
        } else if (presaleAddress === "0xEc4B208733bf6B4F58959F454f418330E6e729F9") {
          setPublicSoldAmount(new BigNumber("200000000000000000000000"));
          setParticipants(+participantsCount as number + 14);
        } else {
          setPublicSoldAmount(new BigNumber(publicSold));
          setParticipants(+participantsCount);
        }

        setPrivateSoldAmount(new BigNumber(privateSold));
        setSwappedByUser(new BigNumber(ftBalance));
        setSwappedByUserPrivate(new BigNumber(privateSoldFunds));
        setUserMemo(memo);
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetActualInfo()
    }
  }, [account, presaleContract, isMountedRef, blockNumber])

  useEffect(() => {
    if (!loading && account) {
      fetchActualInfo()
    }
  }, [fetchActualInfo, loading, account])

  const handleDeposit = useCallback(async (
    amount: string,
    memo: string | null,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    setLoading(true)

    if (fundTokenAddress === "0x0000000000000000000000000000000000000000") {
      const receipt = await sendEthTransaction(
        memo === null ? await presaleContract?.methods.deposit(amount) : await presaleContract?.methods.depositWithMemo(amount, memo),
        amount,
        callbacks
      ) as TransactionReceipt
      setBlockNumber(receipt.blockNumber)
      setLoading(false)
    } else {
      const receipt = await sendTransaction(
        memo === null ? await presaleContract?.methods.deposit(amount) : await presaleContract?.methods.depositWithMemo(amount, memo),
        callbacks
      ) as TransactionReceipt
      setBlockNumber(receipt.blockNumber)
      setLoading(false)
    }

  }, [presaleContract, sendTransaction])

  const handleDepositPrivate = useCallback(async (
    amount: string,
    memo: string | null,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    setLoading(true)

    if (fundTokenAddress === "0x0000000000000000000000000000000000000000") {
      const receipt = await sendEthTransaction(
        memo === null ? await presaleContract?.methods.depositPrivateSale(amount) : await presaleContract?.methods.depositPrivateSaleWithMemo(amount, memo),
        amount,
        callbacks
      ) as TransactionReceipt

      setBlockNumber(receipt.blockNumber)
      setLoading(false)
    } else {
      const receipt = await sendTransaction(
        memo === null ? await presaleContract?.methods.depositPrivateSale(amount) : await presaleContract?.methods.depositPrivateSaleWithMemo(amount, memo),
        callbacks
      ) as TransactionReceipt

      setBlockNumber(receipt.blockNumber)
      setLoading(false)
    }
  }, [presaleContract, sendTransaction])

  return {
    fundTokenBalance,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swapExchangeRate,
    swappedByUser,
    swappedByUserPrivate,
    participants,
    onDeposit: handleDeposit,
    onDepositPrivate: handleDepositPrivate,
    fundsDecimals,
    rewardsDecimals,
    userMemo
  }
}
