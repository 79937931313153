import { useMemo } from 'react';
import { useWeb3 } from '../../hooks/useWeb3';
import {
  getEvmosPadTokenContract,
  getTokenContract,
  getEthBalance,
  getProjectPresaleContract,
  getProjectVestingContract,
  getWhitelistContract,
  getStakingContract,
  getFarmingContract
} from '../getContract';
import { ContractAddress } from '../address';
import { Contract } from 'web3-eth-contract';

export const useEvmosPadToken = () => {
  const web3 = useWeb3();
  return useMemo(() => getEvmosPadTokenContract(web3), [web3]);
};

export const useEvmosPadStaking = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getStakingContract(address, web3), [web3])
}

export const useEvmosPadFarming = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getFarmingContract(address, web3), [web3])
}

export const useTokenContract = (address?: ContractAddress) => {
  const web3 = useWeb3()
  return useMemo(() => getTokenContract(address, web3), [address, web3])
}

export const useEthBalance = (address: any) => {
  const web3 = useWeb3()
  return useMemo(() => getEthBalance(address, web3), [address, web3])
}

export const useProjectPresaleContract = (address?: string) => {
  const web3 = useWeb3()
  return useMemo(() => getProjectPresaleContract(address, web3), [address, web3])
}

export const useProjectVestingContract = (address?: string) => {
  const web3 = useWeb3()
  return useMemo(() => getProjectVestingContract(address, web3), [address, web3])
}

export const useMultipleVestingContracts = (addresses: string[]) => {
  const web3 = useWeb3()
  return useMemo(
    () => addresses.filter(address =>  address !== "0x0000000000000000000000000000000000000000").map(address => getProjectVestingContract(address, web3) as Contract),
    [addresses, web3]
  )
}

export const useWhitelistContract = (address?: string) => {
  const web3 = useWeb3()
  return useMemo(() => getWhitelistContract(address, web3), [address, web3])
}
