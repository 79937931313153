import { GasPriceOptions, GasPriceResponse, GPolyGasPriceResponse, MaticGasPriceResponse } from './types';

export const mapGasPrice = (response: GasPriceResponse): GasPriceOptions => {
  if ((response as GPolyGasPriceResponse).result) {
    let result = (response as GPolyGasPriceResponse).result
    return {
      safeLow: +result.SafeGasPrice / 2,
      standard: +result.SafeGasPrice,
      fast: +result.ProposeGasPrice,
      fastest: +result.FastGasPrice,
    }
  }

  return response as MaticGasPriceResponse
}
