import { useWeb3React } from '@web3-react/core';
import { useState, useEffect, useCallback } from 'react';
import { useWhitelistContract } from './useContract';
import BigNumber from 'bignumber.js';
import { useIsMounted } from '../../hooks/useIsMounted';
import {
  getWhitelistStatus,
  sendDataForExclusiveWhitelist,
  sendDataForWhitelist
} from '../../api/whitelist';
import { WhitelistRequestFormData } from '../../api/whitelist/types';

export enum WhitelistStatuses {
  not_submitted = 'not_submitted',
  in_review = 'in_review',
  rejected = 'rejected',
  passed = 'passed'
}

export type WhitelistStatus = keyof typeof WhitelistStatuses;

interface WhiteListReturn {
  whiteListStatus: WhitelistStatus | null;
  allowedPrivatePresale: boolean;
  maxAllocation: BigNumber;
  privateMaxAllocation: BigNumber;
  whitelistedUsers: number;
  applyToWhitelist: (
    formData: WhitelistRequestFormData
  ) => Promise<WhitelistStatus | null>;
}

export const useWhitelist = (
  projectId: string,
  contract?: string
): WhiteListReturn => {
  const isMountedRef = useIsMounted();
  const { account } = useWeb3React();
  const [whiteListStatus, setStatus] = useState<WhitelistStatus | null>(null);
  const [allowedPrivatePresale, setAllowedPrivatePresale] = useState(false);
  const [maxAllocation, setMaxAllocation] = useState<BigNumber>(
    new BigNumber(0)
  );
  const [privateMaxAllocation, setPrivateMaxAllocation] = useState<BigNumber>(
    new BigNumber(0)
  );
  const [whitelistedUsers, setWhitelistedUsers] = useState(0);
  const whitelistContract = useWhitelistContract(contract);

  useEffect(() => {
    const getStatus = async () => {
      if (!account) {
        setStatus(WhitelistStatuses.not_submitted);
        return;
      }
      const status = await getWhitelistStatus({ account, projectId });
      isMountedRef.current &&
        setStatus(status || WhitelistStatuses.not_submitted);
    };

    const getAllocation = async () => {
      if (!whitelistContract || !account) {
        setMaxAllocation(new BigNumber(0));
        setStatus(WhitelistStatuses.not_submitted);
        setPrivateMaxAllocation(new BigNumber(0));
        setAllowedPrivatePresale(false);
        return;
      }
      const {
        '1': whitelisted,
        '2': allocation,
        '3': allowedPrivate,
        '4': privateAllocation
      } = await whitelistContract.methods.getUser(account).call();
      const users = await whitelistContract.methods.totalUsers().call();

      if (isMountedRef.current) {
        setMaxAllocation(new BigNumber(allocation));
        setWhitelistedUsers(users);
        setStatus(
          whitelisted
            ? WhitelistStatuses.passed
            : WhitelistStatuses.not_submitted
        );
        setPrivateMaxAllocation(new BigNumber(privateAllocation));
        setAllowedPrivatePresale(!!allowedPrivate);
      }
    };

    getStatus();
    getAllocation();
  }, [projectId, whitelistContract, account, isMountedRef]);

  const applyToWhitelist = useCallback(
    async (formData) => {
      if (!account) {
        return null;
      }
      const status = await sendDataForWhitelist({
        project_id: projectId,
        wallet_address: account,
        form_data: formData
      });

      if (status) {
        setStatus(status);
      }
      return status;
    },
    [projectId, account]
  );

  return {
    whiteListStatus,
    allowedPrivatePresale,
    maxAllocation,
    privateMaxAllocation,
    whitelistedUsers,
    applyToWhitelist
  };
};
