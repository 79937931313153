import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import {
  useEthBalance,
  useTokenContract,
} from './useContract';
import { differenceInDays, toDate } from 'date-fns';
import { sendExceptionReport } from '../../utils/errors';
import { useIsMounted } from '../../hooks/useIsMounted';
import { useTransactions } from './useTransactions';
import { BlockNumber } from 'web3-core';

export const useTokenBalance = (
  tokenAddress?: string,
  loading = false,
  blockNumber: BlockNumber = 'latest'
): BigNumber => {
  const isMountedRef = useIsMounted()
  const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
  const { account } = useWeb3React();
  const ethBalance = useEthBalance(account);
  const tokenContract = useTokenContract(tokenAddress);
  const { callTransaction } = useTransactions()

  const fetchBalance = useCallback(async () => {
    if (!tokenContract || !account) {
      setBalance(new BigNumber(0))
      return
    }

    if (tokenAddress === "0x0000000000000000000000000000000000000000") {
      const res = await ethBalance;
      if (!!res) isMountedRef.current && setBalance(new BigNumber(res))
    } else {
      const res = await callTransaction(
        tokenContract.methods.balanceOf(account),
        blockNumber
      )
      isMountedRef.current && setBalance(new BigNumber(res))
    }
  }, [tokenContract, account, isMountedRef, blockNumber])

  useEffect(() => {
    if (!loading && account && tokenContract) {
      fetchBalance();
    }
  }, [loading, blockNumber, account, tokenContract]);

  return balance;
};

export const useDecimals = (tokenAddress?: string) => {
  const isMountedRef = useIsMounted()
  const [decimals, setDecimals] = useState(18);
  const tokenContract = useTokenContract(tokenAddress)

  useEffect(() => {
    const getDecimals = async () => {
      if (tokenAddress === "0x0000000000000000000000000000000000000001") {
        setDecimals(0)
        return
      }

      if (!tokenContract || tokenAddress === "0x0000000000000000000000000000000000000000") {
        setDecimals(18)
        return
      }

      const result = await tokenContract.methods.decimals().call()
      if (isMountedRef.current) {
        setDecimals(+result)
      }
    }

    getDecimals()
  }, [tokenContract, isMountedRef])

  return decimals
}
