import React from 'react';
import { RoundButton } from '../../RoundButton';
import {
  ProjectDescription,
  ProjectDescriptionProps
} from '../ProjectDescription';
import { ProjectStats, ProjectNonContractProps } from '../ProjectStats';
import {
  NormalizedProjectInfo,
  ProjectStatusesProps,
  ProjectStatusProp,
  TokenInfo
} from '../types';
import { usePresale } from '../../../contracts/hooks/usePresale';
import { balanceToNumber } from '../../../utils/balanceFormatter';
import './ProjectWidget.css';
import { generatePath } from 'react-router-dom';
import { RoutesPaths } from '../../../router/constants';
import { BigNumber } from 'bignumber.js';
import { useWhitelist } from '../../../contracts/hooks/useWhitelist';

export interface ProjectProps
  extends Omit<
      ProjectNonContractProps,
      'status' | 'fundTokenName' | 'rewardTokenName' | 'participants'
    >,
    Omit<ProjectDescriptionProps, 'status' | 'fundTokenName'> {
  masterTag?: string;
  tag?: string;
  id: string;
  mainColor?: string;
  status: ProjectStatusProp;
  presaleContract?: string;
  fundToken: TokenInfo;
  rewardToken: TokenInfo;
  project: NormalizedProjectInfo;
}

const ProjectWidget = ({
  masterTag,
  tag,
  id,
  mainColor,
  logo,
  name,
  projectType,
  subtitle,
  description,
  status,
  network,
  fundToken,
  rewardToken,
  presaleContract,
  privateOpens,
  privateCloses,
  opens,
  closes,
  socialLinks,
  project
}: ProjectProps) => {
  const {
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swapExchangeRate,
    participants,
    fundsDecimals,
    rewardsDecimals
  } = usePresale(presaleContract, fundToken.address, rewardToken.address);
  const { whiteListStatus } = useWhitelist(
    id,
    project?.presale.whitelist_contract_address
  );

  return (
    <div className="project-widget tile">
      <div className="project-widget__inner">
        <ProjectDescription
          logo={logo}
          name={name}
          subtitle={subtitle}
          status={status}
          network={network}
          fundTokenName={fundToken.name}
          description={description}
          socialLinks={socialLinks}
          projectType={projectType}
        />

        <ProjectStats
          id={id}
          whiteListStatus={whiteListStatus as any}
          fundTokenName={fundToken.name}
          rewardTokenName={rewardToken.name}
          swapExchangeRate={balanceToNumber(swapExchangeRate, 0)}
          status={status}
          privateOpens={privateOpens}
          privateCloses={privateCloses}
          opens={opens}
          closes={closes}
          totalAmount={balanceToNumber(
            project.presale.total_swap_amount
              ? new BigNumber(project.presale.total_swap_amount)
              : totalSwapAmount,
            fundsDecimals
          )}
          totalRewards={balanceToNumber(
            project.presale.total_rewards_amount
              ? new BigNumber(project.presale.total_rewards_amount)
              : totalRewardsAmount,
            rewardsDecimals
          )}
          fundsSwapped={balanceToNumber(
            project.presale.funds_swapped
              ? new BigNumber(project.presale.funds_swapped)
              : fundsSwapped,
            fundsDecimals
          )}
          participants={participants}
        />

        <div className="project-widget__buttons">
          <RoundButton
            to={
              masterTag
                ? RoutesPaths.MAIN + masterTag
                : generatePath(RoutesPaths.PROJECT, { id })
            }
            size="small"
          >
            More
          </RoundButton>
        </div>
      </div>
    </div>
  );
};

ProjectWidget.defaultProps = {
  mainColor: 'rgba(254, 111, 70, 0.5)'
};

export { ProjectWidget };
