import { NetworksIDs, NetworkId } from './networks';

export type ContractAddress = string;
export type WalletAddress = string;

interface Contracts {
  evmospadToken: ContractAddress;
  evmospadStaking1: ContractAddress;
  evmospadStaking2: ContractAddress;
  lpToken: ContractAddress;
  evmospadFarming: ContractAddress;
}

const networksTokenAddresses: Record<NetworkId, Contracts> = {
  [NetworksIDs.bnb]: {
    evmospadToken: '0x7Ff4AFbDadD33d9CfAE759956A6f61732deF4965',
    evmospadStaking1: '0xB2F0733c4ed3632158c9a58C1e0bbF1F54AF8a3A',
    evmospadStaking2: '0xFF0c7d27a39B8F980de5fabeAb849976B45d01cF',
    lpToken: "0x48C866aBed5240E3FCB788F95Cc5c41459191df3",
    evmospadFarming: "0x8eefc0d6d0831630983fdb67988bbfac19df34cb"
  },
};

export const tokenAddresses: Contracts =
  networksTokenAddresses[NetworksIDs.bnb];
