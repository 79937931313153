import './ProjectsSlider.css'
import { NormalizedProjectInfo } from '../types';
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import { RoundButton } from '../../RoundButton';
import { LocationDescriptor } from 'history';
import { ProjectSlide } from '../ProjectSlide';
import { useMemo } from 'react';
import { ReactComponent as NextArrow } from '../../../assets/forward_arrow.svg'
import { LoadingWrap } from '../../LoadingWrap';

interface Props {
  title: string
  projectsLink: LocationDescriptor
  linkText: string
  projects: NormalizedProjectInfo[]
  loadingProjects: boolean
}

SwiperCore.use([Navigation, Pagination])

export const ProjectsSlider = ({
  title,
  projectsLink,
  linkText,
  projects,
  loadingProjects
}: Props) => {
  const enableSwiping = useMemo(() => (projects.length > 2), [projects])

  const navigationOptions = {
    nextEl: '.projects-slider__nav-next',
    prevEl: null
  }

  const paginationOptions = {
    el: '.projects-slider__pagination'
  }

  return (
    <div className="projects-slider">
      <div className='projects-slider__info'>
        <h2 className='title'>{title}</h2>
        <div className='projects-slider__pagination' />
        <RoundButton
          size="large"
          to={projectsLink}
        >
          {linkText}
        </RoundButton>
      </div>
      <LoadingWrap loading={loadingProjects}>
        {
          !!projects.length && (
            <Swiper
              className="projects-slider-slides"
              loop={enableSwiping}
              spaceBetween={32}
              slidesPerView="auto"
              touchEventsTarget="wrapper"
              navigation={navigationOptions}
              pagination={paginationOptions}
            >
              {
                projects.map((project, index) => (
                  <SwiperSlide
                    key={index + project.id}
                  >
                    <ProjectSlide
                      id={project.id}
                      logo={project.assets.logo_image_url}
                      projectType={project.project_type}
                      NFTImage={project.assets.nft_image_url}
                      name={project.name}
                      subtitle={project.info.subtitle}
                      status={project.presale.statusMessage}
                      network={project.network}
                      fundTokenName={project.presale.fund_token.name}
                      mainColor={project.info.main_color}
                    />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          )
        }
      </LoadingWrap>
      {
        enableSwiping && (
          <span className='projects-slider__nav-next'>
            <NextArrow />
          </span>
        )
      }
    </div>
  )
}
