import { NetworkId, NetworksIDs, selectedNetwork } from '../networks';
import { GasPriceOptions, GasPriceOracleCall } from '../../api/gas-stations/types';
import { getBscGasPrice } from '../../api/gas-stations';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useMemo } from 'react';
import { useDispatch } from '../../hooks/useDispatch';
import { useSelector } from '../../hooks/useSelector';
import { ActionType, StateGasPrice } from '../../store/types';
import { sendExceptionReport } from '../../utils/errors';

const oracles: Record<NetworkId, GasPriceOracleCall> = {
  [NetworksIDs.bnb]: getBscGasPrice,
}

const initialGasPrices: GasPriceOptions = {
  safeLow: 0,
  standard: 0,
  fast: 0,
  fastest: 0
}

const gasPriceMultiplier = parseFloat(process.env.REACT_APP_GAS_PRICE_MULTIPLIER || '1.2')

const mapGasResponse = ({
  safeLow,
  standard,
  fast,
  fastest
}: GasPriceOptions): GasPriceOptions => ({
  safeLow,
  standard,
  fast,
  fastest
})

export const useGasPrice = () => {
  const dispatch = useDispatch()
  const gasPrices = useSelector<StateGasPrice>(state => state.gasPrices)
  const gasPrice = useMemo(() => Math.floor((gasPrices.fastest || 0) * gasPriceMultiplier), [gasPrices])

  const { chainId } = useWeb3React()
  const networkId = useMemo(() => chainId ? chainId.toString() : selectedNetwork, [chainId])
  const gasPriceCall = useCallback(oracles[networkId], [networkId])

  const getGasPrice = useCallback(async () => {
    try {
      const response = await gasPriceCall()
      dispatch({
        type: ActionType.SET_GAS_PRICE,
        payload: mapGasResponse(response)
      })
    } catch (err) {
      sendExceptionReport(err)
      dispatch({
        type: ActionType.SET_GAS_PRICE,
        payload: initialGasPrices
      })
    }
  }, [gasPriceCall])

  return {
    gasPrice,
    getGasPrice
  }
}
