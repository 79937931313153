import React from 'react'
import { NormalizedProjectInfo } from '../types';
import { ProjectWidget } from '../ProjectWidget';
import { Col } from 'react-bootstrap';
import { projectGetters } from '../../../contracts/getters/projectGetters';

interface Props {
  projects: NormalizedProjectInfo[]
}

export const ProjectsList = ({
    projects
}: Props) => {

  return (
    <>
      {projects.map(project =>
        <Col key={project.id} lg={{ span: 4 }}>
          <ProjectWidget
            masterTag={project.masterTag}
            tag={project.tag}
            id={project.id}
            name={project.name}
            projectType={project.project_type}
            subtitle={project.info.subtitle}
            mainColor={project.info.main_color}
            logo={project.assets.logo_image_url}
            description={project.info.description}
            network={project.network}
            status={project.presale.statusMessage}
            fundToken={project.presale.fund_token}
            rewardToken={project.presale.reward_token}
            presaleContract={project.presale.presale_contract_address}
            opens={project.presale.starts_at_display || project.presale.starts_at}
            closes={project.presale.end_at}
            privateOpens={project.presale.private_starts_at}
            privateCloses={project.presale.private_end_at}
            socialLinks={project.social_links}
            project={project}
          />
        </Col>
      )}
    </>
  )
}
