import { useDecimals, useTokenBalance } from './useBalances';
import { tokenAddresses } from '../address';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useEvmosPadFarming, useEvmosPadStaking, useEvmosPadToken, useTokenContract } from './useContract';
import { useWeb3 } from '../../hooks/useWeb3';
import { useWeb3React } from '@web3-react/core';
import { differenceInDays, differenceInSeconds, isPast, toDate } from 'date-fns';
import { balanceToNumber } from '../../utils/balanceFormatter';
import { NotifyTxCallbacks } from '../notify';
import { sendExceptionReport } from '../../utils/errors';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '../../hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';
import { retry } from '../../utils/promises';
import { Contract } from 'web3-eth-contract';

interface FarmingUserInfoResponse {
  amount: string
  rewardDebt: string
  lastDepositedAt: string
}

interface LoadStatsReturns {
  totalStakedAmount: BigNumber
  rewardPerSecond: BigNumber
  lockPeriod: BigNumber
}

export const useFarming = (farmingAddress: string) => {
  const isMountedRef = useIsMounted()
  const web3 = useWeb3()
  const { account } = useWeb3React()
  const {
    callTransaction,
    sendTransaction
  } = useTransactions()

  const lpTokenContract = useTokenContract(tokenAddresses.lpToken)
  const lpBalance = useTokenBalance(tokenAddresses.lpToken)
  const farmingContract = useEvmosPadFarming(farmingAddress)
  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')

  const [rewards, setRewards] = useState(new BigNumber(0))
  const [staked, setStaked] = useState(new BigNumber(0))
  const [lastStaked, setLastStaked] = useState(0)
  const [lastStakedInSeconds, setLastStakedInSeconds] = useState(0)

  const [totalStaked, setTotalStaked] = useState(new BigNumber(0))
  const [rewardsAPY, setRewardsAPY] = useState(new BigNumber(0))
  const [rewardPerSecond, setRewardPerSecond] = useState(new BigNumber(0))
  const [lockPeriod, setLockPeriod] = useState(new BigNumber(0))

  const decimals = useDecimals(tokenAddresses.evmospadToken)

  const resetUserInfo = () => {
    setRewards(new BigNumber(0))
    setStaked(new BigNumber(0))
    setLastStaked(0)
  }

  const loadUserInfo = useCallback(async () => {
    if (!account || !farmingContract) {
      resetUserInfo()
      return
    }

    try {
      await Promise.all([
        callTransaction(
          farmingContract.methods.userInfo(account),
          blockNumber
        ).then(({ amount, lastDepositedAt }) => {
          if (isMountedRef.current) {
            setStaked(new BigNumber(amount))
            if (+lastDepositedAt) {
              setLastStaked(differenceInDays(new Date(), toDate(+lastDepositedAt * 1000)))
              setLastStakedInSeconds(differenceInSeconds(new Date(), toDate(+lastDepositedAt * 1000)))
            }
          }
        }),
        callTransaction(
          farmingContract.methods.pendingReward(account),
          blockNumber
        ).then(result => {
          isMountedRef.current && setRewards(new BigNumber(result))
        })
      ])
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetUserInfo()
    }

  }, [farmingContract, account, web3, isMountedRef, blockNumber])

  const resetStats = () => {
    setTotalStaked(new BigNumber(0))
    setRewardsAPY(new BigNumber(0))
  }

  const loadStatsPromise = async (): Promise<LoadStatsReturns> => {
    let totalStakedAmount = new BigNumber(0)
    let rewardPerSecond = new BigNumber(0)
    let lockPeriod = new BigNumber(0)

    let batch = new web3.BatchRequest()

    await new Promise<void>((resolve, reject) => {
      batch.add(
        lpTokenContract?.methods.balanceOf(farmingAddress).call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            totalStakedAmount = new BigNumber(result)
          }
        )
      )
      batch.add(
        farmingContract.methods.rewardPerSecond().call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            rewardPerSecond = new BigNumber(result)
          }
        )
      )
      batch.add(
        farmingContract.methods.lockPeriod().call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            lockPeriod = new BigNumber(result)
            resolve()
          }
        )
      )
      batch.execute()
    })

    return {
      totalStakedAmount,
      rewardPerSecond,
      lockPeriod,
    }
  }

  const loadStats = useCallback(async () => {
    if (!farmingContract) {
      resetStats()
    }
    try {
      const {
        totalStakedAmount,
        rewardPerSecond,
        lockPeriod
      } = await loadStatsPromise()

      if (isMountedRef.current) {
        setTotalStaked(totalStakedAmount)
        setRewardPerSecond(rewardPerSecond)
        setLockPeriod(lockPeriod)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetStats()
    }
  }, [farmingContract, web3, isMountedRef])

  useEffect(() => {
    if (!loading && account && farmingContract) {
      loadUserInfo()
    }
  }, [account, farmingContract, loading, blockNumber])

  useEffect(() => {
    if (!loading) {
      loadStats()
    }
  }, [loading, farmingContract])

  const onStake = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !farmingContract) {
      return
    }
    setLoading(true)

    const receipt = await sendTransaction(
      await farmingContract.methods.deposit(amount, account),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [account, farmingContract, sendTransaction])

  const onUnstake = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !farmingContract) {
      return
    }
    setLoading(true)

    const receipt = await sendTransaction(
      await farmingContract.methods.withdraw(amount, account),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [account, farmingContract, sendTransaction])

  const onClaim = useCallback(async (
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !farmingContract) {
      return
    }
    setLoading(true)

    const receipt = await sendTransaction(
      await farmingContract.methods.harvest(account),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [account, farmingContract, sendTransaction])

  return {
    lpBalance,
    rewards,
    staked,
    totalStaked,
    rewardsAPY,
    lockPeriod,
    lastStaked,
    lastStakedInSeconds,
    decimals,
    onStake,
    onUnstake,
    onClaim
  }
}
