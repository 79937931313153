import { NormalizedProjectInfo } from '../components/Project/types';
import { GasPriceOptions } from '../api/gas-stations/types';

export enum ActionType {
  SET_LOADING,
  SET_PLATFORM_SETTINGS,
  SET_PROJECTS,
  SET_WHITElLISTED_PROJECTS,
  SET_PROJECT,
  SET_KYC_DATA,
  SET_GAS_PRICE,
}

export interface SetLoading {
  type: ActionType.SET_LOADING
}

export interface SetProjects {
  type: ActionType.SET_PROJECTS
  payload: NormalizedProjectInfo[]
}

export interface SetWhitelistedProjects {
  type: ActionType.SET_WHITElLISTED_PROJECTS,
  payload: NormalizedProjectInfo[]
}

export interface SetProject {
  type: ActionType.SET_PROJECT
  payload: NormalizedProjectInfo | null
}


export interface SetGasPrice {
  type: ActionType.SET_GAS_PRICE
  payload: GasPriceOptions
}

export type StateAction =
  SetLoading |
  SetProjects |
  SetWhitelistedProjects |
  SetProject |
  SetGasPrice


export interface StateGasPrice extends GasPriceOptions {}

export type StateLoading = boolean
export type StateProjects = NormalizedProjectInfo[] | null
export type StateCurrentProject = NormalizedProjectInfo | null

export interface AppState {
  loading: StateLoading
  projects: StateProjects
  whitelistedProjects: StateProjects
  currentProject: StateCurrentProject
  gasPrices: StateGasPrice
}
