import React from 'react';
import { ProjectStatusProp, ProjectType, SocialLink, TokenName } from '../types';
import './ProjectDescription.css';
import { Image } from 'react-bootstrap';
import { ProjectTag } from '../ProjectTag';
import { DynamicImage } from '../../DynamicImage';
import { NetworkName } from '../../../contracts/networks';

export interface ProjectDescriptionProps {
  logo: string
  name: string
  subtitle?: string
  status: ProjectStatusProp
  network: NetworkName
  fundTokenName: TokenName
  description: string
  socialLinks: SocialLink[]
  projectType: ProjectType
}

interface Props extends ProjectDescriptionProps {
}

export const ProjectDescription = ({
  logo,
  name,
  subtitle,
  status,
  network,
  description,
  fundTokenName,
  socialLinks,
  projectType,
}: Props) => {

  return (
    <div className='project-info'>
      <header className='project-info__head'>
        <div>
          <h3 className='project-info__name'>{name}</h3>
          {
            !!socialLinks.length && (
              <div className='project-info__links'>
                {socialLinks.map(link =>
                  <a key={link.name} className="project-info__link" href={link.url} target="_blank" rel="noreferrer noopener">
                    <DynamicImage path={`socials/${link.name}.svg`} />
                  </a>
                )}
              </div>
            )
          }
          <div className='project-info__tags'>
            <ProjectTag type="status" value={status} />
            {/* <ProjectTag type="network" value={network} /> */}
            <ProjectTag type="token" projectType={projectType} value={fundTokenName} />
          </div>
          {!!subtitle && (
            <p className='project-info__subtitle'>
              {subtitle}
            </p>
          )}
        </div>
        <Image src={logo} roundedCircle className='project-info__logo' />
      </header>
      <div className='project-info__description' dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};
