import { useCallback, useContext } from 'react';
import { useSelector } from '../../hooks/useSelector';
import {
  getCompletedProjects,
  getFeaturedProjects,
  getOpenProjects,
  getUpcomingProjects
} from '../../store/getters';
import { AppContext } from '../../store';
import { useWeb3React } from '@web3-react/core';
import { ActionType, AppState } from '../../store/types';
import {
  _getProjectById,
  _getProjects,
  _getWhitelistedProjects
} from '../../api/projects';

export const useProjectsState = () => {
  const { state, dispatch } = useContext(AppContext)
  const { account } = useWeb3React()

  const {
    loading,
    projects,
    currentProject,
    whitelistedProjects
  } = useSelector<AppState>(state => state)
  const openProjects = getOpenProjects(state)
  const upcomingProjects = getUpcomingProjects(state)
  const featuredProjects = getFeaturedProjects(state)
  const completedProjects = getCompletedProjects(state)

  const getProjects = useCallback(async () => {
    dispatch({ type: ActionType.SET_LOADING })
    const payload = await _getProjects()
    dispatch({
      type: ActionType.SET_PROJECTS,
      payload
    })
  }, [])

  const getWhitelistedProjects = useCallback(async () => {
    if (!account) {
      dispatch({
        type: ActionType.SET_WHITElLISTED_PROJECTS,
        payload: []
      })
      return
    }
    dispatch({ type: ActionType.SET_LOADING })
    const payload = await _getWhitelistedProjects(account)
    dispatch({
      type: ActionType.SET_WHITElLISTED_PROJECTS,
      payload: payload
    })
  }, [account])

  const getProjectById = useCallback(async (id: string) => {
    dispatch({ type: ActionType.SET_LOADING })
    const payload = await _getProjectById(id)
    dispatch({
      type: ActionType.SET_PROJECT,
      payload,
    })
  }, [])

  return {
    loading,
    projects,
    currentProject,
    whitelistedProjects,
    openProjects,
    upcomingProjects,
    completedProjects,
    featuredProjects,
    getProjects,
    getWhitelistedProjects,
    getProjectById
  }
}
