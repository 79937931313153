import React, { useCallback, useMemo, useState } from 'react';
import { useWeb3 } from '../../hooks/useWeb3';
import { selectedNetwork, selectedNetworkInfo } from '../../contracts/networks';
import { Image } from 'react-bootstrap';
import { sendExceptionReport } from '../../utils/errors';
import { RoundButton, RoundButtonProps } from '../RoundButton';
import EVMOSIcon from '../../assets/networks/evmos.png';
import { Spinner } from '../Spinner';

type Props = Pick<RoundButtonProps, 'color' | 'size' | 'wide' | 'className'> & {
  text?: React.ReactNode;
};

const AddNetworkButton = ({ color, size, wide, className, text }: Props) => {
  const [loading, setLoading] = useState(false);
  const web3 = useWeb3();

  const displayName = useMemo(() => selectedNetworkInfo.userFriendlyName, [
    web3
  ]);

  const networkParams = useMemo(() => {
    const {
      name: chainName,
      networkId,
      nativeCurrency,
      rpcUrl,
      explorerUrl
    } = selectedNetworkInfo;
    const chainId = web3.utils.numberToHex(networkId);
    const rpcUrls = [rpcUrl];
    const blockExplorerUrls = [explorerUrl];

    return [
      {
        chainId,
        chainName,
        nativeCurrency,
        rpcUrls,
        blockExplorerUrls
      }
    ];
  }, [web3]);

  const addNetwork = useCallback(async () => {
    if (!web3.givenProvider) {
      return alert(
        'Please make sure that you have MetaMask extension installed for your browser and connected the wallet to the website.'
      );
    }

    if (web3.givenProvider.networkVersion === selectedNetwork) {
      return alert(
        `${networkParams[0].chainName} network is already exist in your wallet`
      );
    }

    setLoading(true);
    try {
      await web3.givenProvider.request({
        method: 'wallet_addEthereumChain',
        params: networkParams
      });
    } catch (err) {
      if (err.code !== 4001) {
        sendExceptionReport(err);
      }
    }
    setLoading(false);
  }, [web3, networkParams, loading]);

  return (
    <RoundButton
      className={`add-network-button ${className}`}
      type="button"
      color={color}
      size={size}
      wide={wide}
      disabled={loading}
      onClick={addNetwork}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <span>
            {text} {displayName}
          </span>
          <Image
            className="ms-1"
            src={EVMOSIcon}
            width={22}
            height={21}
            alt="BSC"
          />
        </>
      )}
    </RoundButton>
  );
};

AddNetworkButton.defaultProps = {
  color: 'LIGHT',
  size: 'small',
  wide: false,
  text: 'Switch to'
};

export { AddNetworkButton };
