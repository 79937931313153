import { instanceWithSignature as API } from '../config';
import {
  NormalizedProjectInfo,
  ProjectResponseInfo
} from '../../components/Project/types';
import { sendExceptionReport } from '../../utils/errors';
import { normalizeProject } from './mapping';

import { ethers } from 'ethers';

const TestProject1 = {
  project_type: 'token_presale',
  chainId: 9000,
  name: 'Test 1',
  id: 'Test1',
  whitelisting: {
    starts_at: '2022-09-20T13:00:00.000Z',
    end_at: '2022-09-21T01:00:00.000Z'
  },
  presale: {
    starts_at: '2022-09-23T01:00:00.000Z',
    end_at: '2022-10-05T01:00:00.000Z',
    private_starts_at: '2022-09-20T02:00:00.000Z',
    private_end_at: '2022-09-23T01:00:00.000Z',
    status: 'active',
    vesting_contract_address: '0x619478c2EE69E824590718296338a1DE0f41155e',
    presale_contract_address: '0x288A76CE3D6F76024Bc33510da36B5E6996B4E73',
    reward_token: {
      address: '0x8C5c4a0420330eAA9e799cf94C4d2c21e075BAAD',
      name: 'Test1'
    },
    whitelist_contract_address: '0xb29806b7E5C9906F6190698EEF4AC1856e07Af4b',
    min_allocation: 0,
    max_allocation: 0,
    fund_token: {
      name: 'TUSDT',
      address: '0x9Abb1bBb08EFA1105eAaf3474652759dd3558A53',
      icon:
        'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707'
    }
  },
  info: {
    main_color: 'rgba(114, 111, 70, 0.5)',
    description: '<p>This is test project 1</p>'
  },
  assets: {
    logo_image_url:
      'https://pbs.twimg.com/profile_images/1507525321322471425/ag3UJYHJ_400x400.png'
  },
  social_links: [
    {
      name: 'web',
      url: '#'
    },
    {
      name: 'twitter',
      url: '#'
    },
    {
      name: 'telegram',
      url: '#'
    },
    {
      name: 'discord',
      url: '#'
    }
  ],
  network: 'EVM TestNet'
};

const TestProject2 = {
  project_type: 'token_presale',
  chainId: 9000,
  name: 'Test 2',
  id: 'Test2',
  whitelisting: {
    starts_at: '2022-09-19T13:00:00.000Z',
    end_at: '2022-10-05T13:00:00.000Z'
  },
  presale: {
    starts_at: '2022-10-07T13:00:00.000Z',
    end_at: '2022-10-08T13:00:00.000Z',
    private_starts_at: '2022-10-06T13:00:00.000Z',
    private_end_at: '2022-10-07T13:00:00.000Z',
    status: 'active',
    vesting_contract_address: ethers.constants.AddressZero,
    presale_contract_address: ethers.constants.AddressZero,
    reward_token: {
      address: ethers.constants.AddressZero,
      name: 'Test2'
    },
    whitelist_contract_address: ethers.constants.AddressZero,
    min_allocation: 0,
    max_allocation: 0,
    fund_token: {
      name: 'TUSDT',
      address: '0x9Abb1bBb08EFA1105eAaf3474652759dd3558A53',
      icon:
        'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707'
    }
  },
  info: {
    main_color: 'rgba(114, 111, 70, 0.5)',
    description: '<p>This is test 2</p>'
  },
  assets: {
    logo_image_url:
      'https://pbs.twimg.com/profile_images/1507525321322471425/ag3UJYHJ_400x400.png'
  },
  social_links: [
    {
      name: 'web',
      url: '#'
    },
    {
      name: 'twitter',
      url: '#'
    },
    {
      name: 'telegram',
      url: '#'
    },
    {
      name: 'discord',
      url: '#'
    }
  ],
  network: 'EVM TestNet'
};

const TestProject3 = {
  project_type: 'token_presale',
  chainId: 9000,
  name: 'Test 3',
  id: 'Test3',
  whitelisting: {
    starts_at: '2022-09-19T13:00:00.000Z',
    end_at: '2022-09-20T05:00:00.000Z'
  },
  presale: {
    starts_at: '2022-09-20T05:00:00.000Z',
    end_at: '2022-10-04T05:00:00.000Z',
    private_starts_at: '2022-09-20T01:00:00.000Z',
    private_end_at: '2022-09-20T02:00:00.000Z',
    status: 'active',
    vesting_contract_address: "0x034E67f0998C77a34CFf88EbBB2B1FA457212E6e",
    presale_contract_address: "0x111e6518935EF033f1cB0722377d868Bbe09A9b3",
    reward_token: {
      address: "0x874CBdD48DEAdc972742E5a364D59920DBb42eE9",
      name: 'Test3'
    },
    whitelist_contract_address: "0xb29806b7E5C9906F6190698EEF4AC1856e07Af4b",
    min_allocation: 0,
    max_allocation: 0,
    fund_token: {
      name: 'TUSDT',
      address: '0x9Abb1bBb08EFA1105eAaf3474652759dd3558A53',
      icon:
        'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707'
    }
  },
  info: {
    main_color: 'rgba(114, 111, 70, 0.5)',
    description: '<p>This is test 3</p>'
  },
  assets: {
    logo_image_url:
      'https://pbs.twimg.com/profile_images/1507525321322471425/ag3UJYHJ_400x400.png'
  },
  social_links: [
    {
      name: 'web',
      url: '#'
    },
    {
      name: 'twitter',
      url: '#'
    },
    {
      name: 'telegram',
      url: '#'
    },
    {
      name: 'discord',
      url: '#'
    }
  ],
  network: 'EVM TestNet'
};

const TestProject4 = {
  project_type: 'token_presale',
  chainId: 9000,
  name: 'Test 4',
  id: 'Test4',
  whitelisting: {
    starts_at: '2022-09-19T13:00:00.000Z',
    end_at: '2022-09-20T05:00:00.000Z'
  },
  presale: {
    starts_at: '2022-09-20T05:00:00.000Z',
    end_at: '2022-09-20T08:00:00.000Z',
    private_starts_at: '2022-09-20T01:00:00.000Z',
    private_end_at: '2022-09-20T02:00:00.000Z',
    status: 'active',
    vesting_contract_address: "0xCab217E14AEb6486B402934880b83301c19D4Dd4",
    presale_contract_address: "0x111e6518935EF033f1cB0722377d868Bbe09A9b3",
    reward_token: {
      address: "0x874CBdD48DEAdc972742E5a364D59920DBb42eE9",
      name: 'Test4'
    },
    whitelist_contract_address: "0xb29806b7E5C9906F6190698EEF4AC1856e07Af4b",
    min_allocation: 0,
    max_allocation: 0,
    fund_token: {
      name: 'TUSDT',
      address: '0x9Abb1bBb08EFA1105eAaf3474652759dd3558A53',
      icon:
        'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707'
    }
  },
  info: {
    main_color: 'rgba(114, 111, 70, 0.5)',
    description: '<p>This is test 4</p>'
  },
  assets: {
    logo_image_url:
      'https://pbs.twimg.com/profile_images/1507525321322471425/ag3UJYHJ_400x400.png'
  },
  social_links: [
    {
      name: 'web',
      url: '#'
    },
    {
      name: 'twitter',
      url: '#'
    },
    {
      name: 'telegram',
      url: '#'
    },
    {
      name: 'discord',
      url: '#'
    }
  ],
  network: 'EVM TestNet'
};

const projects = [
  TestProject1, TestProject2, TestProject3, TestProject4];

export const _getProjects = async (): Promise<NormalizedProjectInfo[]> => {
  return projects
    .map((project) => normalizeProject(project as ProjectResponseInfo))
    .filter((project) => !project.tag);
};

export const _getProjectsByTag = (tag: string): NormalizedProjectInfo[] => {
  return projects
    .map((project) => normalizeProject(project as ProjectResponseInfo))
    .filter((project) => project.tag === tag);
};

export const _getProjectById = async (
  id: string
): Promise<NormalizedProjectInfo | null> => {
  try {
    const index = projects.findIndex((project) => project.id === id);
    if (index < 0) throw Error("Desn't exist");

    return normalizeProject(projects[index] as ProjectResponseInfo);
  } catch (err) {
    sendExceptionReport(err);
    return null;
  }
};

export const getMultipleVestingForWallet = async (
  wallet_address: string
): Promise<string[] | null> => {
  try {
    const { data: contracts } = await API.get('/get-multiple-vestings', {
      params: {
        wallet_address
      }
    });

    return contracts;
  } catch (err) {
    sendExceptionReport(err);
    return null;
  }
};

export const _getWhitelistedProjects = async (
  wallet_address: string
): Promise<NormalizedProjectInfo[]> => {
  try {
    const { data } = await API.get('/get-whitelisted-projects', {
      params: {
        wallet_address
      }
    });

    return data.map((project: ProjectResponseInfo) =>
      normalizeProject(project)
    );
  } catch (err) {
    sendExceptionReport(err);
    return [];
  }
};
