import { useEffect, useState, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { web3NoAccount } from '../utils/web3';

export const useWeb3 = () => {
  const { library } = useWeb3React();
  const refWeb3 = useRef(library);
  const [web3, setweb3] = useState(library ? new Web3(library) : web3NoAccount);

  useEffect(() => {
    if (library !== refWeb3.current) {
      setweb3(library ? new Web3(library) : web3NoAccount);
      refWeb3.current = library;
    }
  }, [library]);

  return web3;
};
