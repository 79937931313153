import React, { useCallback, useMemo, useState } from 'react';
import './Farming.css'

import { tokenAddresses } from '../../contracts/address';

import { FarmingItem } from './FarmingItem';

export const Farming = () => {
  return (
    <div className="account-page page">
      <FarmingItem stakingAddress={tokenAddresses.evmospadFarming} title="Farming" forceAPY='1000'/>
    </div>
  )
}
