import Web3, { utils } from 'web3';
import { AbiItem } from 'web3-utils';

import evmospadTokenAbi from '../contracts/abi/evmospadToken.json';
import stakingAbi from '../contracts/abi/staking.json';
import farmingAbi from '../contracts/abi/farming.json';
import commonTokenAbi from '../contracts/abi/commonToken.json';
import projectPresaleAbi from '../contracts/abi/projectPresale.json';
import projectVestingAbi from '../contracts/abi/projectVesting.json';
import whitelistAbi from '../contracts/abi/whitelist.json';
import { ContractAddress, tokenAddresses } from './address';
import { web3NoAccount } from '../utils/web3';

const getContract = (abi: any, address: ContractAddress, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi as AbiItem, address);
};

export const getEthBalance = (address: any, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount;
  if (address) return _web3.eth.getBalance(address);
};

export const getEvmosPadTokenContract = (web3?: Web3) => {
  return getContract(evmospadTokenAbi, tokenAddresses.evmospadToken, web3);
};
export const getStakingContract = (address: string, web3?: Web3) => {
  return getContract(stakingAbi, address, web3)
}
export const getFarmingContract = (address: string, web3?: Web3) => {
  return getContract(farmingAbi, address, web3)
}
export const getTokenContract = (tokenAddress?: ContractAddress, web3?: Web3) => {
  if (tokenAddress) return getContract(commonTokenAbi, tokenAddress, web3)
}
export const getProjectPresaleContract = (string?: string, web3?: Web3) => {
  if (string && string !== "0x0000000000000000000000000000000000000000") return getContract(projectPresaleAbi, string, web3)
}
export const getProjectVestingContract = (string?: string, web3?: Web3) => {
  if (string && string !== "0x0000000000000000000000000000000000000000") return getContract(projectVestingAbi, string, web3)
}
export const getWhitelistContract = (string?: string, web3?: Web3) => {
  if (string && string !== "0x0000000000000000000000000000000000000000") return getContract(whitelistAbi, string, web3)
}
