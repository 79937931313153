import { InjectedConnector } from '@web3-react/injected-connector';
import { networks, selectedNetwork } from './contracts/networks';

const networkId = +networks[selectedNetwork].networkId

if (!networkId) {
  throw new Error('No network selected')
}

export enum ConnectorNames {
  MetaMask = 'MetaMask'
}

type ConnectorByName = {
  [connectorName in ConnectorNames]: any;
};

export const MetaMask = new InjectedConnector({
  supportedChainIds: [networkId]
});

export const connectorsByName: ConnectorByName = {
  [ConnectorNames.MetaMask]: MetaMask
};
