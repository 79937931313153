import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  TimerWrap,
  ProjectsList,
  LoadingWrap,
  PortfolioList,
} from '../../components';
import { dueDateForProjects } from '../../constants';
import './Projects.css';
import { useProjectsState } from '../../contracts/hooks/useProjects';

export const Claim = () => {
  const {
    loading,
    openProjects,
    upcomingProjects,
    completedProjects,
    getProjects
  } = useProjectsState()

  useEffect(() => {
    getProjects()
  }, [])

  return (
    <div className="projects-page page">
      <section className="project-top">
        <Container className="py-5">
        </Container>
      </section>
      <section className="projects-completed">
        <Container>
          <h1 className="title mb-5">Claim your tokens</h1>
          <PortfolioList projects={completedProjects} />
        </Container>
      </section>
    </div>
  );
};
