import axios from 'axios';
import { sendEnvExceptionReport } from '../../utils/errors';
import { GasPriceOptions } from './types';
import { mapGasPrice } from './mappers';
import { NetworksIDs, selectedNetwork } from '../../contracts/networks';

const oracleEndpoint = new URL(process.env.REACT_APP_BNB_GASSTATION || '')
if (!oracleEndpoint && selectedNetwork === NetworksIDs.bnb) {
  sendEnvExceptionReport(new Error('REACT_APP_BNB_GASSTATION variable is not specified'))
}
const baseURL = oracleEndpoint.origin
const path = oracleEndpoint.pathname
const params = Object.fromEntries(oracleEndpoint.searchParams.entries())

const instance = axios.create({
  baseURL: baseURL
})

const fallbackInstance = axios.create({
  baseURL: 'https://gbsc.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle'
})

export const getBscGasPrice = async (): Promise<GasPriceOptions> => {
  try {
    const { data } = await instance.get(path, {
      params
    })
    return mapGasPrice(data)
  } catch (err) {
    const { data } = await fallbackInstance.get('/')
    return mapGasPrice(data)
  }

}
