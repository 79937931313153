import {
  NormalizedProjectInfo,
  ProjectStatusesProps,
  ProjectStatusProp
} from '../components/Project/types';
import { AppState } from './types';

const makeProjectsGetter = (...args: ProjectStatusProp[]): (state: AppState) => NormalizedProjectInfo[] => {

  return (state) => {
    const { projects } = state
    if (!projects?.length) {
      return []
    }
    return [...projects].filter(project => args.includes(project.presale.statusMessage)).sort((a, b) => {
      if (!a.presale.starts_at) {
        return 1;
      }
      if (!b.presale.starts_at) {
        return -1;
      }
      if (args.includes(ProjectStatusesProps.Closed)) {
        return b.presale.starts_at.getTime() - a.presale.starts_at.getTime();
      }
      return a.presale.starts_at.getTime() - b.presale.starts_at.getTime();
    })
  }
}

export const getOpenProjects = makeProjectsGetter(ProjectStatusesProps.Open, ProjectStatusesProps['Private Open'])
export const getUpcomingProjects = makeProjectsGetter(ProjectStatusesProps['Coming Soon'], ProjectStatusesProps['Registration Open'], ProjectStatusesProps['Registration Closed'])
export const getCompletedProjects = makeProjectsGetter(ProjectStatusesProps.Closed)

export const getFeaturedProjects = (state: AppState): NormalizedProjectInfo[] => {
  const featuredProjects = [...getOpenProjects(state), ...getUpcomingProjects(state)]

  if (!featuredProjects.length) {
    return []
  }

  return featuredProjects
    .sort(
      (a, b) =>
        (+(b.whitelisting.starts_at ?? 0) - +(a.whitelisting.starts_at ?? 0))
    ).slice(0, 5)
}
