import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './IndividualProject.css';
import { generatePath, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Image
} from 'react-bootstrap';
import {
  BackLink,
  BalanceItem,
  DynamicImage,
  LoadingWrap,
  PortfolioList,
  ProjectDescription,
  ProjectStats,
  RoundButton
} from '../../components';
import {
  NormalizedProjectInfo,
  ProjectStatusesProps,
  ProjectStatusProp
} from '../../components/Project/types';
import {
  useWhitelist,
  WhitelistStatuses
} from '../../contracts/hooks/useWhitelist';
import classNames from 'classnames';
import {
  balanceToNumber,
  balanceToCurrency,
  balanceToNumeric,
  numericToBalance,
  numericToUint256
} from '../../utils/balanceFormatter';
import { useApproval } from '../../contracts/hooks/useApproval';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import { ReactComponent as LinkArrow } from '../../assets/link-arrow.svg';
import WLIcon from '../../assets/account.svg';
import WLNotPassedIcon from '../../assets/account-warning.svg';
import { usePresale } from '../../contracts/hooks/usePresale';
import { ApprovalSteps } from '../../components/ApprovalSteps';
import { useProjectsState } from '../../contracts/hooks/useProjects';
import { RoutesPaths } from '../../router/constants';
import BigNumber from 'bignumber.js';
import { projectGetters } from '../../contracts/getters/projectGetters';
import { useWeb3React } from '@web3-react/core';

interface ParamTypes {
  id: string;
}

export const IndividualProject = () => {
  const { id } = useParams<ParamTypes>();

  const {
    loading,
    currentProject: project,
    getProjectById
  } = useProjectsState();

  useEffect(() => {
    if (id !== project?.id || !project) {
      getProjectById(id);
    }
  }, [id, project]);

  const statusMessage: ProjectStatusProp = useMemo(
    () => project?.presale.statusMessage || ProjectStatusesProps['Coming Soon'],
    [project]
  );

  const requireMemo: boolean = useMemo(() => !!project?.presale.require_memo, [
    project
  ]);
  const memoName: string = useMemo(
    () => (project?.presale.memo_name ? project?.presale.memo_name : ''),
    [project]
  );

  const isPrivatePresaleInProgress = useMemo(
    () => statusMessage === ProjectStatusesProps['Private Open'],
    [statusMessage]
  );
  const isPublicPresaleInProgress = useMemo(
    () => statusMessage === ProjectStatusesProps.Open,
    [statusMessage]
  );
  const isPresaleInProgress = useMemo(
    () => isPublicPresaleInProgress || isPrivatePresaleInProgress,
    [isPublicPresaleInProgress, isPrivatePresaleInProgress]
  );

  const { allowance, onApprove } = useApproval(
    project?.presale.fund_token.address,
    project?.presale.presale_contract_address
  );
  const {
    whiteListStatus,
    maxAllocation,
    privateMaxAllocation,
    allowedPrivatePresale
  } = useWhitelist(id, project?.presale.whitelist_contract_address);
  const {
    fundTokenBalance: balance,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swappedByUser,
    swappedByUserPrivate,
    swapExchangeRate,
    participants,
    onDeposit,
    onDepositPrivate,
    fundsDecimals,
    rewardsDecimals,
    userMemo
  } = usePresale(
    project?.presale.presale_contract_address,
    project?.presale.fund_token.address,
    project?.presale.reward_token.address
  );

  const remainingFundsToSwap = useMemo(() => {
    return totalSwapAmount.minus(fundsSwapped);
  }, [totalSwapAmount, fundsSwapped]);

  const availableAllocation = useMemo(() => {
    return maxAllocation.minus(swappedByUser).plus(swappedByUserPrivate);
  }, [maxAllocation, swappedByUser]);

  const availablePrivateAllocation = useMemo(() => {
    return privateMaxAllocation.minus(swappedByUserPrivate);
  }, [privateMaxAllocation, swappedByUserPrivate]);

  const maxSwapAmount = useMemo(() => {
    return balanceToNumeric(
      BigNumber.min(
        isPublicPresaleInProgress
          ? availableAllocation
          : availablePrivateAllocation,
        balance,
        remainingFundsToSwap
      ),
      fundsDecimals
    );
  }, [
    remainingFundsToSwap,
    isPublicPresaleInProgress,
    availableAllocation,
    availablePrivateAllocation,
    balance,
    fundsDecimals
  ]);

  const [memo, setMemo] = useState(userMemo);

  const [amountToSwap, setAmountToSwap] = useState('0');
  const setMaxToSwap = useCallback(() => {
    setAmountToSwap(maxSwapAmount);
  }, [maxSwapAmount]);

  const disableApproval = useMemo(() => {
    return (
      whiteListStatus !== WhitelistStatuses.passed ||
      allowance.isGreaterThanOrEqualTo(
        numericToBalance(amountToSwap, fundsDecimals)
      ) ||
      +amountToSwap <= 0
    );
  }, [whiteListStatus, allowance, amountToSwap, fundsDecimals]);

  const disableSwapping = useMemo(() => {
    return (
      whiteListStatus !== WhitelistStatuses.passed ||
      +amountToSwap <= 0 ||
      !balanceToNumber(allowance, fundsDecimals) ||
      numericToBalance(maxSwapAmount, fundsDecimals).isLessThan(
        numericToBalance(amountToSwap, fundsDecimals)
      ) ||
      allowance.isLessThan(numericToBalance(amountToSwap, fundsDecimals))
    );
  }, [maxSwapAmount, amountToSwap, allowance, whiteListStatus, fundsDecimals]);

  const warningMessage = useMemo(() => {
    if (
      numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(
        remainingFundsToSwap
      )
    ) {
      return 'Exceeds the remaining funds';
    }
    if (
      numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(
        isPublicPresaleInProgress
          ? availableAllocation
          : availablePrivateAllocation
      )
    ) {
      return 'Exceeds available amount.';
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(balance)) {
      return 'Exceeds your balance';
    }
    if (remainingFundsToSwap.isZero()) {
      return 'No funds available';
    }
  }, [
    amountToSwap,
    balance,
    availableAllocation,
    isPublicPresaleInProgress,
    availablePrivateAllocation,
    fundsDecimals,
    remainingFundsToSwap
  ]);

  const handleSwap = useCallback(async () => {
    if (disableSwapping) {
      return;
    }
    const amount = numericToUint256(amountToSwap, fundsDecimals);
    await onDeposit(amount, requireMemo ? memo : null, {
      onHash: () => setAmountToSwap('0')
    });
  }, [amountToSwap, onDeposit, fundsDecimals, disableSwapping]);

  const handleSwapPrivate = useCallback(async () => {
    if (disableSwapping) {
      return;
    }
    const amount = numericToUint256(amountToSwap, fundsDecimals);
    await onDepositPrivate(amount, requireMemo ? memo : null, {
      onHash: () => setAmountToSwap('0')
    });
  }, [amountToSwap, onDepositPrivate, fundsDecimals, disableSwapping]);

  // const { account } = useWeb3React()

  // const {
  //   whitelistedProjects: projects,
  //   getWhitelistedProjects
  // } = useProjectsState()

  // useEffect(() => {
  //   getWhitelistedProjects()
  // }, [account])

  return (
    <div className="project-page page">
      <LoadingWrap loading={loading}>
        <section className="details-section">
          <Container>
            {!!project && (
              <Row className="gx-4 gy-4 gx-xl-5 gy-xl-5">
                <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                  <BackLink
                    to={
                      project.tag
                        ? RoutesPaths.MAIN + project.tag
                        : RoutesPaths.PROJECTS
                    }
                  >
                    Projects
                  </BackLink>
                  <ProjectDescription
                    logo={project.assets.logo_image_url}
                    name={project.name}
                    projectType={project.project_type}
                    subtitle={project.info.subtitle}
                    status={statusMessage}
                    network={project.network}
                    fundTokenName={project.presale.fund_token.name}
                    description={project.info.description}
                    socialLinks={project.social_links}
                  />
                </Col>
                <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                  <ProjectStats
                    id={project.id}
                    whiteListStatus={whiteListStatus as any}
                    totalAmount={balanceToNumber(
                      project.presale.total_swap_amount
                        ? new BigNumber(project.presale.total_swap_amount)
                        : totalSwapAmount,
                      fundsDecimals
                    )}
                    totalRewards={balanceToNumber(
                      project.presale.total_rewards_amount
                        ? new BigNumber(project.presale.total_rewards_amount)
                        : totalRewardsAmount,
                      rewardsDecimals
                    )}
                    fundsSwapped={balanceToNumber(
                      project.presale.funds_swapped
                        ? new BigNumber(project.presale.funds_swapped)
                        : fundsSwapped,
                      fundsDecimals
                    )}
                    participants={participants}
                    fundTokenName={project.presale.fund_token.name}
                    rewardTokenName={project.presale.reward_token.name}
                    swapExchangeRate={balanceToNumber(
                      project.presale.swap_rate
                        ? new BigNumber(project.presale.swap_rate)
                        : swapExchangeRate,
                      0
                    )}
                    status={statusMessage}
                    privateOpens={project.presale.private_starts_at}
                    privateCloses={project.presale.private_end_at}
                    opens={
                      project.presale.starts_at_display ||
                      project.presale.starts_at
                    }
                    closes={project.presale.end_at}
                    minAllocation={project.presale.min_allocation}
                    maxAllocation={project.presale.max_allocation}
                    expanded
                  />
                </Col>
              </Row>
            )}
          </Container>
        </section>
        {
          // private allocation
          !!project && isPrivatePresaleInProgress && allowedPrivatePresale && (
            <section className="swap-section">
              <Container>
                <Row className="align-items-center">
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    <form noValidate className="swap-form tile">
                      <p className="form-message text-center">
                        {balanceToCurrency(swappedByUser, fundsDecimals)}{' '}
                        {project.presale.fund_token.name} /{' '}
                        {balanceToCurrency(privateMaxAllocation, fundsDecimals)}{' '}
                        {project.presale.fund_token.name}
                      </p>
                      {!!warningMessage && (
                        <div className="form-message form-message--warning text-center">
                          <InfoIcon />
                          <span>{warningMessage}</span>
                        </div>
                      )}
                      <InputGroup className="swap-form__input-group input-group-big">
                        <InputGroup.Prepend>
                          <Image
                            rounded
                            src={project.presale.fund_token.icon}
                          />
                          <span>{project.presale.fund_token.name}</span>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="0.0"
                          type="number"
                          inputMode="numeric"
                          value={amountToSwap}
                          onChange={(e) => setAmountToSwap(e.target.value)}
                          isInvalid={disableSwapping}
                          isValid={!disableSwapping}
                        />
                        <InputGroup.Append>
                          <RoundButton
                            size="small"
                            color="DARK"
                            onClick={setMaxToSwap}
                          >
                            MAX
                          </RoundButton>
                        </InputGroup.Append>
                      </InputGroup>
                      {requireMemo && (
                        <InputGroup className="swap-form__input-group input-group-medium">
                          <FormControl
                            placeholder={memoName}
                            type="text"
                            inputMode="numeric"
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            isInvalid={disableSwapping}
                            isValid={!disableSwapping}
                          />
                        </InputGroup>
                      )}
                      <div className="swap-form__buttons">
                        <RoundButton
                          size="small"
                          disabled={disableApproval}
                          onClick={() =>
                            onApprove(
                              numericToUint256(amountToSwap, fundsDecimals)
                            )
                          }
                        >
                          Approve
                        </RoundButton>
                        <RoundButton
                          size="small"
                          disabled={
                            disableSwapping || (requireMemo && memo === '')
                          }
                          onClick={handleSwapPrivate}
                        >
                          Buy
                        </RoundButton>
                      </div>
                    </form>
                  </Col>
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    <div className="project-page__wallet-info">
                      <BalanceItem
                        image={
                          project.presale.fund_token.icon ??
                          '/token-logos/locked.svg'
                        }
                        title="Available allocation"
                        balance={balanceToCurrency(
                          availablePrivateAllocation,
                          fundsDecimals
                        )}
                        token={project.presale.fund_token.name}
                      />
                      {/* <div
                        className={classNames('whitelist-badge', {
                          invalid: whiteListStatus !== WhitelistStatuses.passed
                        })}
                      >
                        <LoadingWrap loading={!whiteListStatus}>
                          <span
                            className={classNames({
                              'red-text':
                                whiteListStatus !== WhitelistStatuses.passed
                            })}
                          >
                            Your wallet is{' '}
                            {whiteListStatus !== WhitelistStatuses.passed &&
                              'NOT'}{' '}
                            whitelisted
                          </span>
                        </LoadingWrap>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )
        }
        {
          // public allocation
          !!project && isPublicPresaleInProgress && (
            <section className="swap-section">
              <Container>
                <Row className="align-items-center">
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    <form noValidate className="swap-form tile">
                      <p className="form-message text-center">
                        {balanceToCurrency(swappedByUser, fundsDecimals)}{' '}
                        {project.presale.fund_token.name} /{' '}
                        {balanceToCurrency(maxAllocation, fundsDecimals)}{' '}
                        {project.presale.fund_token.name}
                      </p>
                      {!!warningMessage && (
                        <div className="form-message form-message--warning text-center">
                          <InfoIcon />
                          <span>{warningMessage}</span>
                        </div>
                      )}
                      <InputGroup className="swap-form__input-group input-group-big">
                        <InputGroup.Prepend>
                          <Image
                            rounded
                            src={project.presale.fund_token.icon}
                          />
                          <span>{project.presale.fund_token.name}</span>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="0.0"
                          type="number"
                          inputMode="numeric"
                          value={amountToSwap}
                          onChange={(e) => setAmountToSwap(e.target.value)}
                          isInvalid={disableSwapping}
                          isValid={!disableSwapping}
                        />
                        <InputGroup.Append>
                          <RoundButton
                            size="small"
                            color="DARK"
                            onClick={setMaxToSwap}
                          >
                            MAX
                          </RoundButton>
                        </InputGroup.Append>
                      </InputGroup>
                      {requireMemo && (
                        <InputGroup className="swap-form__input-group input-group-medium">
                          <FormControl
                            placeholder={memoName}
                            type="text"
                            inputMode="numeric"
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            isInvalid={disableSwapping}
                            isValid={!disableSwapping}
                          />
                        </InputGroup>
                      )}
                      <div className="swap-form__buttons">
                        <RoundButton
                          size="small"
                          disabled={disableApproval}
                          onClick={() =>
                            onApprove(
                              numericToUint256(amountToSwap, fundsDecimals)
                            )
                          }
                        >
                          Approve
                        </RoundButton>
                        <RoundButton
                          size="small"
                          disabled={
                            disableSwapping || (requireMemo && memo === '')
                          }
                          onClick={handleSwap}
                        >
                          Buy
                        </RoundButton>
                      </div>
                    </form>
                  </Col>
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    <div className="project-page__wallet-info">
                      <BalanceItem
                        image={
                          project.presale.fund_token.icon ??
                          '/token-logos/locked.svg'
                        }
                        title="Available allocation"
                        balance={balanceToCurrency(
                          availableAllocation,
                          fundsDecimals
                        )}
                        token={project.presale.fund_token.name}
                      />
                      {/* <div
                        className={classNames('whitelist-badge', {
                          invalid: whiteListStatus !== WhitelistStatuses.passed
                        })}
                      >
                        <LoadingWrap loading={!whiteListStatus}>
                          <span
                            className={classNames({
                              'red-text':
                                whiteListStatus !== WhitelistStatuses.passed
                            })}
                          >
                            Your wallet is{' '}
                            {whiteListStatus !== WhitelistStatuses.passed &&
                              'NOT'}{' '}
                            whitelisted
                          </span>
                        </LoadingWrap>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )
        }
        {!!project &&
          statusMessage === ProjectStatusesProps.Closed &&
          whiteListStatus === WhitelistStatuses.passed && (
            <section className="claim-section">
              <Container>
                <div className="claim-block tile">
                  <div className="claim-block__title">
                    <DynamicImage path="claim-image.svg" />
                    <h4>Claim your tokens</h4>
                  </div>
                </div>
              </Container>
            </section>
          )}
      </LoadingWrap>
    </div>
  );
};
