import { sendEnvExceptionReport } from '../utils/errors';

const polygonPrivateKey = process.env.REACT_APP_POLYGON_RPC_PRIVATE_KEY;

export enum NetworksIDs {
  matic = '137',
  maticmum = '80001',
  bnb = '56',
  bnbt = '97',
  evmos = '9001',
  evmost = '9000'
}

export enum NetworkNames {
  matic = 'Matic Mainnet',
  maticmum = 'Matic Testnet Mumbai',
  bnb = 'Binance Smart Chain Mainnet',
  bnbt = 'Binance Smart Chain Testnet',
  evmos = 'Evmos Mainnet',
  evmost = 'Evmos Testnet'
}

export enum NetworkUserFriendlyNames {
  matic = 'Polygon',
  maticmum = 'Mumbai',
  bnb = 'BSC',
  bnbt = 'BSC Testnet',
  evmos = 'Evmos Mainnet',
  evmost = 'Evmos Testnet'
}

export type NetworkName = NetworkNames[number];
export type NetworkId = NetworksIDs[number];
export type NetworkUserFriendlyName = NetworkUserFriendlyNames[number];

interface NetworkCurrency {
  name: string;
  symbol: string;
  decimals: number;
}

export interface NetworkInfo {
  name: NetworkName;
  userFriendlyName: NetworkUserFriendlyName;
  networkId: NetworkId;
  nativeCurrency: NetworkCurrency;
  rpcUrl: string;
  privateRPC?: string;
  explorerUrl: string;
}

export type NetworksList = Record<NetworkId, NetworkInfo>;

export const networks: NetworksList = {
  [NetworksIDs.maticmum]: {
    name: NetworkNames.maticmum,
    userFriendlyName: NetworkUserFriendlyNames.maticmum,
    networkId: NetworksIDs.maticmum,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: 'https://rpc-mumbai.maticvigil.com/',
    explorerUrl: 'https://mumbai.polygonscan.com/'
  },
  [NetworksIDs.matic]: {
    name: NetworkNames.matic,
    userFriendlyName: NetworkUserFriendlyNames.matic,
    networkId: NetworksIDs.matic,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: 'https://polygon-rpc.com/',
    // rpcUrl: 'https://rpc-mainnet.maticvigil.com/',
    privateRPC: polygonPrivateKey
      ? `https://polygon-mainnet.g.alchemy.com/v2/${polygonPrivateKey}`
      : '',
    explorerUrl: 'https://polygonscan.com/'
  },
  [NetworksIDs.bnb]: {
    name: NetworkNames.bnb,
    userFriendlyName: NetworkUserFriendlyNames.bnb,
    networkId: NetworksIDs.bnb,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    explorerUrl: 'https://bscscan.com'
  },
  [NetworksIDs.bnbt]: {
    name: NetworkNames.bnbt,
    userFriendlyName: NetworkUserFriendlyNames.bnbt,
    networkId: NetworksIDs.bnbt,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    explorerUrl: 'https://testnet.bscscan.com/'
  },
  [NetworksIDs.evmos]: {
    name: NetworkNames.evmos,
    userFriendlyName: NetworkUserFriendlyNames.evmos,
    networkId: NetworksIDs.evmos,
    nativeCurrency: {
      name: 'EVMOS',
      symbol: 'EVMOS',
      decimals: 18
    },
    rpcUrl: 'https://eth.bd.evmos.org:8545/',
    explorerUrl: 'https://evm.evmos.org/'
  },
  [NetworksIDs.evmost]: {
    name: NetworkNames.evmost,
    userFriendlyName: NetworkUserFriendlyNames.evmost,
    networkId: NetworksIDs.evmost,
    nativeCurrency: {
      name: 'tEVMOS',
      symbol: 'tEVMOS',
      decimals: 18
    },
    rpcUrl: 'https://eth.bd.evmos.dev:8545',
    explorerUrl: 'https://evm.evmos.dev/'
  }
};

export const selectedNetwork = process.env
  .REACT_APP_SELECTED_NETWORK_ID as NetworkId;
export const selectedNetworkInfo = networks[selectedNetwork];
export const selectedNetworkName = networks[selectedNetwork].name;
if (!selectedNetwork) {
  sendEnvExceptionReport(new Error(`Selected network ID isn't provided`));
}

export const supportedNetworks: Record<NetworkId, NetworkInfo> = Object.entries(
  networks
).reduce((acc, [id, network]) => {
  acc[id] = networks[id];
  return acc;
}, {} as Record<NetworkId, NetworkInfo>);
