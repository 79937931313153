import React from 'react';
import { RoutesPaths } from '../../router/constants';
import { Link, useRouteMatch } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { AddNetworkButton, RoundButton } from '../';
import { useWeb3React } from '@web3-react/core';
import { shorterETHAddress } from '../../utils/string';
import { MetaMask } from '../../connectors';
import './Header.css';
import { selectedNetwork } from '../../contracts/networks';

const ConnectIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.061 6.61196C23.5787 6.61196 23.9984 6.1923 23.9984 5.6746V4.69037C23.9984 2.10409 21.8943 0 19.308 0C19.308 0 4.61448 0.0022028 4.57825 0.00646779C3.34145 0.0496801 2.15339 0.580414 1.29702 1.47775C0.419225 2.39749 -0.0383943 3.59375 0.00467745 4.85239C0.00289646 4.87625 0.0019591 18.3723 0.0019591 18.3723C0.0019591 21.4754 2.52655 24 5.62969 24H19.308C21.8943 24 23.9984 21.8959 23.9984 19.3096V12.1928C23.9984 9.60654 21.8943 7.50245 19.308 7.50245H4.68872C3.18374 7.50245 1.94989 6.32616 1.87973 4.8245C1.84406 4.06093 2.11875 3.33204 2.65314 2.77206C3.19527 2.20402 3.95664 1.87828 4.74196 1.87828C4.76966 1.87828 19.3079 1.87472 19.3079 1.87472C20.8605 1.87472 22.1236 3.13782 22.1236 4.69037V5.6746C22.1237 6.1923 22.5433 6.61196 23.061 6.61196ZM4.68872 9.37717H19.308C20.8605 9.37717 22.1236 10.6403 22.1236 12.1928V19.3096C22.1236 20.8622 20.8605 22.1253 19.308 22.1253H5.62969C3.56028 22.1253 1.87668 20.4417 1.87668 18.3723V8.44361C2.66069 9.03086 3.63484 9.37717 4.68872 9.37717Z"
        fill="#c49adf"
      />
    </svg>
  );
};

interface Props {}

export const Header = (props: Props) => {
  const { account, activate, active, chainId } = useWeb3React();
  const stakingPath = useRouteMatch(RoutesPaths.STAKING);
  const farmingPath = useRouteMatch(RoutesPaths.FARMING);
  const projectsPath = useRouteMatch(RoutesPaths.PROJECTS);
  const closedProjectsPath = useRouteMatch(RoutesPaths.COMPLETED);
  const claimPath = useRouteMatch(RoutesPaths.CLAIM);

  const onClickWallet = () => {
    activate(MetaMask);
  };

  return (
    // <Container as="header" fluid>
    <Navbar bg="dark" expand="lg">
      <Navbar.Brand>
        <Link to={RoutesPaths.MAIN}>
          <img
            src={logo}
            alt="Logo"
            width="50px"
            className="d-inline-block align-top"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse className="justify-content-end">
        <Nav.Link
          as={Link}
          active={projectsPath?.isExact}
          to={RoutesPaths.PROJECTS}
        >
          Projects
        </Nav.Link>
        <Nav.Link
          as={Link}
          active={closedProjectsPath?.isExact}
          to={RoutesPaths.COMPLETED}
        >
          Completed
        </Nav.Link>
        <Nav.Link as={Link} active={claimPath?.isExact} to={RoutesPaths.CLAIM}>
          Claim
        </Nav.Link>
        <Nav.Link
          as={Link}
          active={stakingPath?.isExact}
          to={RoutesPaths.STAKING}
        >
          Staking
        </Nav.Link>
        <Nav.Link
          as={Link}
          active={farmingPath?.isExact}
          to={RoutesPaths.FARMING}
        >
          Farming
        </Nav.Link>
        <div className="center-items">
          {+selectedNetwork !== chainId && <AddNetworkButton text="Add" />}
        </div>
        <div className="center-items">
          <RoundButton color="DARK" onClick={onClickWallet}>
            <>
              {active ? shorterETHAddress(account as string) : 'Connect Wallet'}
            </>
          </RoundButton>
        </div>
      </Navbar.Collapse>
    </Navbar>
    // </Container>
  );
};
