import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Image } from 'react-bootstrap';
import {
  ArrowIcon,
  LoadingWrap,
  RoundButton,
  Spinner
} from '../../';
import { RoutesPaths } from '../../../router/constants';
import { Link, generatePath } from 'react-router-dom';
import { NormalizedProjectInfo } from '../../Project/types';
import { balanceToCurrency, balanceToNumber } from '../../../utils/balanceFormatter';
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';
import { ReactComponent as LinkArrow } from '../../../assets/link-arrow.svg';
import classNames from 'classnames';
import './PortfolioItem.css'
import { useMultipleVesting } from '../../../contracts/hooks/useMultipleVesting';
import { useWeb3React } from '@web3-react/core';
import { getMultipleVestingForWallet } from '../../../api/projects';
import { usePresale } from '../../../contracts/hooks/usePresale';
import BigNumber from 'bignumber.js';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { projectGetters } from '../../../contracts/getters/projectGetters';
import { useVesting } from '../../../contracts/hooks/useVesting';

interface Props {
  project: NormalizedProjectInfo
}

export const PortfolioItem = ({ project }: Props) => {
  const isMountedRef = useIsMounted()
  const [collapsed, setCollapsed] = useState(true)

  const { account } = useWeb3React()

  const {
    isClaiming,
    totalVested,
    unvested,
    claimed,
    withdrawable,
    withdraw
  } = useVesting(project.presale.vesting_contract_address)

  const {
    swappedByUser,
    swapExchangeRate,
    fundsDecimals,
    rewardsDecimals
  } = usePresale(
    project.presale.presale_contract_address,
    project.presale.fund_token.address,
    project.presale.reward_token.address
  )

  const [currentPrice, setCurrentPrice] = useState(new BigNumber(0.65))
  const currentValue = useMemo(() => {
    return totalVested.multipliedBy(currentPrice)
  },[totalVested, currentPrice])

  const ROI = useMemo(() => {
    let bnROI = currentPrice.minus(swapExchangeRate).div(swapExchangeRate)
    return (balanceToNumber(bnROI, 0) * 100).toFixed(2)
  }, [swapExchangeRate, currentPrice])

  const rewardToken = useMemo(() => {
    return project?.presale.reward_token.name
  }, [project])

  const fundToken = useMemo(() => {
    return project?.presale.fund_token.name
  }, [project])

  return (totalVested.isZero())
    ? <></>
    : (
      <Accordion>
        <div className="portfolio-item tile">
          <div className='portfolio-item__main'>
            <LoadingWrap loading={!project}>
              <div className='portfolio-item__col'>
                <Image src={project.assets.logo_image_url} roundedCircle className="portfolio-item__logo" />
                <h4 className="portfolio-item__name">
                  <Link to={generatePath(RoutesPaths.PROJECT, { id: project.id })}>{project.name}</Link>
                </h4>
              </div>
              <div className='portfolio-item__col'>
                <span>
                  {`${balanceToCurrency(totalVested, rewardsDecimals)} ${rewardToken}`}
                </span>
              </div>
              <div className='portfolio-item__col'>
                <span>
                 {`${balanceToCurrency(unvested, rewardsDecimals)} ${rewardToken}`}
                </span>
              </div>
              <div className='portfolio-item__col'>
                <span>
                  {`${balanceToCurrency(claimed, rewardsDecimals)} ${rewardToken}`}
                </span>
              </div>
              <div className='portfolio-item__col purple-text'>
                <span>
                  {`${balanceToCurrency(withdrawable, rewardsDecimals)} ${rewardToken}`}
                </span>
              </div>
              <div className='portfolio-item__col'>
                  <RoundButton
                    size="large"
                    color="LIGHT"
                    disabled={!balanceToNumber(withdrawable, rewardsDecimals) || isClaiming}
                    onClick={withdraw}
                  >
                    {isClaiming ? <Spinner /> : 'Claim'}
                  </RoundButton>
              </div>
            </LoadingWrap>
          </div>
        </div>
      </Accordion>
    )
}
