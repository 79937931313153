import { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useProjectVestingContract } from './useContract';
import { useWeb3React } from '@web3-react/core';
import { NotifyTxCallbacks } from '../notify';
import { UseVestingReturns, VestingRecipientsResponse } from './useMultipleVesting';
import { sendExceptionReport } from '../../utils/errors';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '../../hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';

export const useVesting = (address?: string): UseVestingReturns => {
  const isMountedRef = useIsMounted()
  const {
    callTransaction,
    sendTransaction
  } = useTransactions()
  const { account } = useWeb3React()

  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const [totalVested, setVested] = useState<BigNumber>(new BigNumber(0))
  const [claimed, setClaimed] = useState<BigNumber>(new BigNumber(0))
  const [withdrawable, setWithdrawable] = useState<BigNumber>(new BigNumber(0))
  const [participants, setParticipants] = useState(0)

  const unvested = useMemo(() => {
    return totalVested.minus(claimed).minus(withdrawable)
  }, [totalVested, claimed, withdrawable])

  const projectVestingContract = useProjectVestingContract(address)

  const resetVestingInfo = () => {
    setVested(new BigNumber(0))
    setClaimed(new BigNumber(0))
    setWithdrawable(new BigNumber(0))
    setParticipants(0)
  }

  const getVestingInfo = useCallback(async () => {
    if (!account || !projectVestingContract) {
      resetVestingInfo()
      return
    }

    try {
      const {
        totalAmount,
        amountWithdrawn
      }: VestingRecipientsResponse = await callTransaction(
        projectVestingContract.methods.recipients(account),
        blockNumber
      )
      const withdrawableAmount = await callTransaction(
        projectVestingContract.methods.withdrawable(account),
        blockNumber
      )
      const participantCount = await callTransaction(
        projectVestingContract.methods.participantCount(),
        blockNumber
      )

      if (isMountedRef.current) {
        setVested(new BigNumber(totalAmount))
        setClaimed(new BigNumber(amountWithdrawn))
        setWithdrawable(new BigNumber(withdrawableAmount))
        setParticipants(+participantCount)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetVestingInfo()
    }
  }, [account, projectVestingContract, isMountedRef, blockNumber])

  useEffect(() => {
    if (!loading && account && projectVestingContract) {
      getVestingInfo()
    }
  }, [loading, account, projectVestingContract, getVestingInfo, blockNumber])

  const withdraw = useCallback(async (
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !projectVestingContract) {
      return
    }
    setLoading(true)

    const receipt = await sendTransaction(
      await projectVestingContract.methods.withdraw(),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [account, projectVestingContract, sendTransaction])

  return {
    isClaiming: loading,
    totalVested,
    unvested,
    claimed,
    withdrawable,
    participants,
    withdraw
  }
}
