import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RoundButton } from '../RoundButton';
import './WrongNetworkModal.css'
import { UnsupportedChainIdError } from '@web3-react/core';
import { selectedNetworkName } from '../../contracts/networks';
import { AddNetworkButton } from '../AddNetworkButton';

interface Props {
  error?: Error
}

export const WrongNetworkModal = ({ error }: Props) => {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setShow(error instanceof UnsupportedChainIdError)
  }, [error])

  return (
    <Modal
      show={show}
      centered
      onHide={() => setShow(false)}
      className="wrong-network-modal"
      contentClassName="tile text-center"
      backdrop="static"
    >
      <Modal.Title as="h4">
        Your wallet is connected to the wrong network        
      </Modal.Title>
      <Modal.Body className="p-0">
        Please change to the {selectedNetworkName} Network
        <div className='wrong-network-modal__buttons'>
          <RoundButton
            size="large"
            wide
            onClick={() => setShow(false)}
          >
            Ignore
          </RoundButton>
          <AddNetworkButton
            size="large"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
