export const RoutesPaths = {
  MAIN: '/',
  STAKING: '/staking',
  FARMING: '/farming',
  PROJECTS: '/projects',
  COMPLETED: '/completed-projects',
  CLAIM: '/claim',
  BITHOTEL: '/bithotel',
  PROJECT: '/projects/:id',
  PORTFOLIO: '/portfolio',
  WHITELIST: '/whitelist/:id'
};
