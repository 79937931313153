import {
  NormalizedProjectInfo, ProjectStatusesProps,
  ProjectTypes,
} from '../../components/Project/types';

type ProjectGetter<T> = (project: NormalizedProjectInfo) => T

const getIsNFTPresaleProject: ProjectGetter<boolean> = project => project.project_type === ProjectTypes.NFT_presale
const getIsNFTClaimProject: ProjectGetter<boolean> = project => project.project_type === ProjectTypes.NFT_claim
const getIsNFTCampaign: ProjectGetter<boolean> = project => getIsNFTPresaleProject(project) || getIsNFTClaimProject(project)
const getIsProjectInProgress: ProjectGetter<boolean> = project =>
  [ProjectStatusesProps['Private Open'], ProjectStatusesProps.Open].includes(project.presale.statusMessage as ProjectStatusesProps)
const getProjectTypeSuffix: ProjectGetter<string> = project => {
  switch (project.project_type) {
    case ProjectTypes.NFT_presale:
    case ProjectTypes.token_presale:
      return 'Pre-sale'
    case ProjectTypes.NFT_claim:
      return 'Claim'
    default:
      return ''
  }
}

export const projectGetters = {
  getIsNFTCampaign,
  getIsNFTPresaleProject,
  getIsNFTClaimProject,
  getIsProjectInProgress,
  getProjectTypeSuffix
}
