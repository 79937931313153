import {
  NormalizedPresaleInfo,
  NormalizedProjectInfo,
  NormalizedWhitelistingInfo,
  ProjectResponseInfo,
  ProjectStatus,
  ProjectStatuses,
  ProjectStatusesProps,
  ProjectStatusProp
} from '../../components/Project/types';
import { isPast } from 'date-fns';
import { isDefined } from '../../utils/object';

export const normalizeStatus = ({
  status,
  whitelistStart,
  whitelistEnd,
  privatePresaleStart,
  privatePresaleEnd,
  presaleStart,
  presaleEnd
}: {
  status: ProjectStatus
  whitelistStart: Date | null
  whitelistEnd: Date | null
  privatePresaleStart?: Date | null
  privatePresaleEnd?: Date | null
  presaleStart?: Date | null
  presaleEnd?: Date | null
} ): ProjectStatusProp => {
  if (!whitelistStart || !whitelistEnd) return ProjectStatusesProps['Coming Soon']
  if (status === ProjectStatuses.completed) return ProjectStatusesProps.Closed

  if (status === ProjectStatuses.active) {
    if (
      (presaleEnd && isPast(presaleEnd)) ||
      (!presaleEnd && privatePresaleEnd && isPast(privatePresaleEnd))
    ) return ProjectStatusesProps.Closed
    if (presaleStart && isPast(presaleStart)) return ProjectStatusesProps.Open
    if (privatePresaleStart && isPast(privatePresaleStart)) return ProjectStatusesProps['Private Open']
    if (isPast(whitelistEnd)) return ProjectStatusesProps['Registration Closed']
    if (isPast(whitelistStart)) return ProjectStatusesProps['Registration Open']
  }

  return ProjectStatusesProps['Coming Soon']
}

export const normalizeDate = (dateString: string | null): Date | null => {
  return dateString ? new Date(dateString) : null
}

export const normalizeProject = (projectInfo: ProjectResponseInfo): NormalizedProjectInfo => {
  let projectWhitelisting: NormalizedWhitelistingInfo = {
    ...projectInfo.whitelisting,
    starts_at: normalizeDate(projectInfo.whitelisting.starts_at),
    end_at: normalizeDate(projectInfo.whitelisting.end_at)
  }

  let projectPresale = {
    ...projectInfo.presale,
    ...(
      isDefined(projectInfo.presale.starts_at) &&
      isDefined(projectInfo.presale.end_at) && {
        starts_at: normalizeDate(projectInfo.presale.starts_at as string | null),
        end_at: normalizeDate(projectInfo.presale.end_at as string | null),
      }
    ),
    ...(
      isDefined(projectInfo.presale.private_starts_at) &&
      isDefined(projectInfo.presale.private_end_at) && {
        private_starts_at: normalizeDate(projectInfo.presale.private_starts_at as string | null),
        private_end_at: normalizeDate(projectInfo.presale.private_end_at as string | null)
      }
    ),
    ...(
      isDefined(projectInfo.presale.starts_at_display) && {
        starts_at_display: normalizeDate(projectInfo.presale.starts_at_display as string | null),
      }
    ),
    statusMessage: ProjectStatusesProps['Coming Soon']
  } as NormalizedPresaleInfo

  projectPresale.statusMessage = normalizeStatus({
    status: projectPresale.status,
    whitelistStart: projectWhitelisting.starts_at,
    whitelistEnd: projectWhitelisting.end_at,
    privatePresaleStart: projectPresale.private_starts_at,
    privatePresaleEnd: projectPresale.private_end_at,
    presaleStart: projectPresale.starts_at,
    presaleEnd: projectPresale.end_at
  })

  return {
    ...projectInfo,
    whitelisting: projectWhitelisting,
    presale: projectPresale
  }
}
