import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import './App.css';
import Routes from './router/Routes';
import Web3 from 'web3';
import { StateProvider } from './store';

const getLibrary = (provider: any): Web3 => {
  return provider;
};

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <StateProvider>
        <Routes />
      </StateProvider>
    </Web3ReactProvider>
  );
}

export default App;
